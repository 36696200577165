import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import PageHeader from './PageHeader.js'
import PageSection from './PageSection.js'
import styled from 'styled-components'
import { Button } from './CommonStylesForms'
import { setSchemaSelected } from '../redux/schemasReducer.js'
import { setNotificationState } from '../redux/notificationsReducer'

import {
  DataTable,
  DataRow,
  DataHeader,
  DataCell,
  AttributeTable,
  AttributeRow,
  IconCell,
  IconClose,
  IconCheck,
} from './CommonStylesTables'

const Chip = styled.div`
  float: right;
  padding: 8px 10px;
  margin-right: 10px;
  line-height: 20px;
`

function Schema(props) {
  const dispatch = useDispatch()
  const schemasState = useSelector((state) => state.schemas)
  const schemaSelected = schemasState.schemaSelected

  const history = props.history
  const schemaId = props.schemaId
  let [schemaAttributes, setSchemaAttributes] = useState([])
  let mappedAttributes = ''

  useEffect(() => {
    if (schemasState.schemas) {
      for (let i = 0; i < schemasState.schemas.length; i++) {
        if (schemasState.schemas[i].schema_id === schemaId) {
          dispatch(setSchemaSelected(schemasState.schemas[i]))
          setSchemaAttributes(schemaSelected.attribute_names)
          break
        } else {
          console.error('Schema not Selected')
        }
      }
    } else {
      console.error('No schemas')
    }
  }, [schemasState.schemas, schemaSelected, schemaId, dispatch])

  if (
    schemaSelected.schema_id !== null &&
    schemaSelected.schema_id !== undefined &&
    schemaAttributes !== undefined
  ) {
    mappedAttributes = schemaAttributes.map((attribute) => {
      return (
        <AttributeRow attribute={attribute} key={attribute.name}>
          <th></th>
          <td>{attribute}</td>
        </AttributeRow>
      )
    })
  }

  return (
    <>
      <div id="contact">
        <PageHeader title={'Schema Details:'} />
        <PageSection>
          <h2>General Information</h2>
          <AttributeTable>
            <tbody>
              <AttributeRow>
                <th>Name:</th>
                <td>{schemaSelected.name || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Schema ID:</th>
                <td>{schemaSelected.schema_id || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Version:</th>
                <td>{schemaSelected.version || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Sequence Number:</th>
                <td>{schemaSelected.sequence_number || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Active:</th>
                <td>{schemaSelected.active || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Workflow:</th>
                <td>{schemaSelected.workflow || ''}</td>
              </AttributeRow>
            </tbody>
          </AttributeTable>
          <h2>Attributes</h2>
          <AttributeTable>
            <tbody>{mappedAttributes}</tbody>
          </AttributeTable>
        </PageSection>
      </div>
    </>
  )
}

export default Schema
