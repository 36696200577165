import React, { useRef, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Select from 'react-select'

import { check } from './CanUser'

import PageHeader from './PageHeader.js'
import PageSection from './PageSection.js'
import PageSection2 from './PageSection2.js'
import styled from 'styled-components'
import FormInvitationEmail from './FormInvitationEmail'
import FormUniversalIssuance from './FormUniversalIssuance'
import { setContactSelected } from '../redux/contactsReducer.js'
import { setNotificationState } from '../redux/notificationsReducer'

import { SubmitBtnModal } from './CommonStylesForms'

import {
  DataTable,
  DataRow,
  DataHeader,
  DataCell,
  AttributeTable,
  AttributeRow,
  IconCheck,
} from './CommonStylesTables'
import { LoadingHolder, Spinner } from './LoadingStyles'

const Chip = styled.div`
  float: right;
  padding: 8px 10px;
  margin-right: 10px;
  line-height: 20px;
`

const SelectContainer = styled.div`
  float: left;
  z-index: 100;
  width: 300px;
  margin-right: 15px;
`
const PingBtn = styled.button`
  float: right;
  padding: 10px 12px;
  margin-right: 10px;
  line-height: 20px;
  color: ${(props) => props.theme.text_light};
  border: none;
  box-shadow: ${(props) => props.theme.drop_shadow};
  background: ${(props) => props.theme.primary_color};
`
const InteractionButton = styled.button`
  float: right;
  padding: 10px 12px;
  margin-right: 10px;
  line-height: 20px;
  color: ${(props) => props.theme.text_light};
  border: none;
  box-shadow: ${(props) => props.theme.drop_shadow};
  background: ${(props) => props.theme.primary_color};
`

function Contact(props) {
  const dispatch = useDispatch()
  const contactsState = useSelector((state) => state.contacts)
  const credentials = useSelector((state) => state.credentials.credentials)

  const schemasState = useSelector((state) => state.schemas)
  const [schemasOptions, setSchemasOptions] = useState([])

  const contactSelected = contactsState.contactSelected
  const [chosenCredential, setChosenCredential] = useState('')

  const [universalModalIsOpen, setUniversalModalIsOpen] = useState(false)
  const closeUniversalModal = () => setUniversalModalIsOpen(false)

  const { history, contactId, sendRequest } = props

  const websocketsState = useSelector((state) => state.websockets)
  const { websocket, readyForWebsocketMessages } = websocketsState

  const submitBtn = useRef()
  const [connections, setConnections] = useState([])
  const [waitingForContact, setWaitingForContact] = useState(true)

  const [emailModalIsOpen, setEmailModalIsOpen] = useState(false)
  const closeEmailModal = () => setEmailModalIsOpen(false)

  const [employmentModalIsOpen, setEmploymentModalIsOpen] = useState(false)
  const closeEmploymentModal = () => setEmploymentModalIsOpen(false)

  useEffect(() => {
    if (schemasState.schemas) {
      for (let i = 0; i < schemasState.schemas.length; i++) {
        schemasOptions.push({
          value: schemasState.schemas[i].name,
          label: schemasState.schemas[i].name,
        })
      }
    }
  }, [schemasState.schemas])

  useEffect(() => {
    //(AmmonBurgi) Stop waiting for contactSelected if the contact_id matches the target ID. If no match is found, fetch the needed contact.
    if (websocket && readyForWebsocketMessages) {
      if (
        Object.keys(contactSelected).length !== 0 &&
        contactSelected.contact_id === contactId
      ) {
        setWaitingForContact(false)
        setConnections(contactSelected.Connections)
      } else {
        setWaitingForContact(true)
        if (check('contacts:read')) {
          sendRequest('CONTACTS', 'GET', {
            contact_id: contactId,
            additional_tables: [],
          })
        }
      }
    }
  }, [contactSelected, contactId, websocket, readyForWebsocketMessages])

  function handleCredChange(e) {
    setChosenCredential(e.label)
  }

  function handleOpen(e) {
    e.preventDefault()

    if (chosenCredential === 'Email') {
      setEmailModalIsOpen((o) => !o)
    } else {
      setUniversalModalIsOpen((o) => !o)
    }
  }

  let [threadId, setThreadId] = useState('')

  const connectionRows = connections.map((connection) => {
    return (
      <DataRow key={connection.connection_id}>
        <DataCell>{connection.connection_id}</DataCell>
        <DataCell className="title-case">{connection.state}</DataCell>
        <DataCell>{new Date(connection.created_at).toLocaleString()}</DataCell>
      </DataRow>
    )
  })

  async function trustPing() {
    const connectionId = contactSelected.Connections[0].connection_id
    props.sendRequest('CONTACT', 'PING', { connectionId: connectionId })
  }

  function openCredential(history, id) {
    if (history !== undefined) {
      history.push('/admin/credentials/' + id)
    }
  }

  const openEmploymentForm = () => {
    setEmploymentModalIsOpen((o) => !o)
  }

  const openEmailForm = () => {
    setEmailModalIsOpen((o) => !o)
  }

  function Pong() {
    if (
      contactSelected.Connections &&
      contactSelected.Connections[0].connection_id ==
        contactsState?.pongData?.connectionId
    ) {
      return (
        <Chip>
          <IconCheck alt="Pong" />
        </Chip>
      )
    } else {
      return <Chip>&nbsp;</Chip>
    }
  }

  const credentialRows = credentials.map((credential_record) => {
    if (
      contactSelected.Connections &&
      contactSelected.Connections[0].connection_id ===
        credential_record.connection_id
    ) {
      const credential_id = credential_record.credential_exchange_id
      const credentialState = credential_record.state.replaceAll('_', ' ') || ''
      const dateCreated =
        new Date(credential_record.created_at).toLocaleString() || ''

      let credentialName = ''
      if (
        credential_record.credential_proposal_dict !== null &&
        credential_record.credential_proposal_dict !== undefined
      ) {
        credentialName = credential_record.credential_proposal_dict.schema_name.replaceAll(
          '_',
          ' '
        )
      }
      return (
        <DataRow
          key={credential_id}
          onClick={() => {
            openCredential(history, credential_id)
          }}
        >
          <DataCell>{credentialName}</DataCell>
          <DataCell className="title-case">{credentialState}</DataCell>
          <DataCell>{dateCreated}</DataCell>
        </DataRow>
      )
    } else {
      return []
    }
  })

  const onDiscoverFeatures = () => {
    props.sendRequest('DISCOVER_FEATURES', 'GET', {
      connection_id: contactSelected.Connections[0].connection_id,
    })

    dispatch(
      setNotificationState({
        message: 'See the backend logs for the result.',
        type: 'notice',
      })
    )
  }

  return (
    <>
      {!waitingForContact ? (
        <div id="contact">
          <PageHeader
            title={'Contact Details: ' + (contactSelected.label || '')}
          />
          <PageSection2>
            <h2>General Information</h2>
            <AttributeTable>
              <tbody>
                <AttributeRow>
                  <th>Contact ID:</th>
                  <td>{contactSelected.contact_id || ''}</td>
                </AttributeRow>
                <AttributeRow>
                  <th>Connection Status:</th>
                  <td>
                    {contactSelected.Connections !== undefined
                      ? contactSelected.Connections[0].state || ''
                      : ''}
                  </td>
                </AttributeRow>
              </tbody>
            </AttributeTable>
            <h2>Contact Information</h2>
            <AttributeTable>
              <tbody>
                <AttributeRow>
                  <th>Name:</th>
                  <td>{contactSelected.label || ''}</td>
                </AttributeRow>
              </tbody>
            </AttributeTable>

            <form>
              <p>Select a Credential to Issue:</p>

              <SelectContainer>
                <Select
                  className="react-select-container"
                  id="chosenCred"
                  name="chosenCred"
                  placeholder="---Choose Credential---"
                  onChange={(e) => handleCredChange(e)}
                  options={schemasOptions}
                />
              </SelectContainer>

              <SubmitBtnModal
                type="submit"
                onClick={(e) => {
                  handleOpen(e)
                }}
                style={{ float: 'left' }}
              >
                Issue
              </SubmitBtnModal>
            </form>

            <InteractionButton onClick={() => onDiscoverFeatures()}>
              Discover Features v2.0
            </InteractionButton>
            <PingBtn
              onClick={() => {
                trustPing()
              }}
            >
              Ping
            </PingBtn>
            <Pong />
            <div style={{ clear: 'both' }}></div>
          </PageSection2>
          <PageSection>
            <DataTable>
              <thead>
                <DataRow>
                  <DataHeader>Credential</DataHeader>
                  <DataHeader>Status</DataHeader>
                  <DataHeader>Date Issued</DataHeader>
                </DataRow>
              </thead>
              <tbody>{credentialRows}</tbody>
            </DataTable>
          </PageSection>
          <PageSection>
            <DataTable>
              <thead>
                <DataRow>
                  <DataHeader>Connection</DataHeader>
                  <DataHeader>Status</DataHeader>
                  <DataHeader>Created At</DataHeader>
                </DataRow>
              </thead>
              <tbody>{connectionRows}</tbody>
            </DataTable>
          </PageSection>
          <FormInvitationEmail
            emailModalIsOpen={emailModalIsOpen}
            closeEmailModal={closeEmailModal}
          />
          <FormUniversalIssuance
            contactId={contactId}
            connections={connections}
            universalModalIsOpen={universalModalIsOpen}
            closeUniversalModal={closeUniversalModal}
            chosenCredential={chosenCredential}
            sendRequest={props.sendRequest}
          />
        </div>
      ) : (
        <LoadingHolder>
          <p>Fetching contacts, please wait...</p>
          <Spinner />
        </LoadingHolder>
      )}
    </>
  )
}

export default Contact
