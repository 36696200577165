import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import QRCode from 'qrcode.react'
import styled from 'styled-components'
import PoweredByImage from '../assets/powered-by.png'
import Select from 'react-select'

import { Logo, PoweredBox, PoweredBy, SubmitBtn } from './CommonStylesForms'

const CompFormVerify = styled.div`
  box-sizing: border-box;
  padding-top: 30px;
`
const FormWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2%;
  background: white;
`

const HeaderVerify = styled.div`
  color: ${(props) => props.theme.primary_color};
  border-bottom: 2px solid #ddd;
  margin-bottom: 30px;
  font-size: 1.9em;
  padding-top: 1em;
  padding-bottom: 3px;
`
const QR = styled(QRCode)`
  display: block;
  margin: auto;
  padding: 20px;
  width: 300px;
  height: 300px;
  background-color: white;
`
const VerChoiceWrapper = styled.span`
  display: flex;
  width: 50%;
  justify-content: space-evenly;
`

const SelectBox = styled(Select)`
  padding-bottom: 15px;
`

function Root(props) {
  const [waitingForInvitation, setWaitingForInvitation] = useState(false)
  const [waitingForConnection, setWaitingForConnection] = useState(false)
  const [waitingForVerification, setWaitingForVerification] = useState(false)
  const [connected, setConnected] = useState(false)
  const [verificationStatus, setVerificationStatus] = useState(undefined)

  const [schemaOptions, setSchemaOptions] = useState([])

  const submitBtn = useRef()

  const contactsState = useSelector((state) => state.contacts)
  const invitationsState = useSelector((state) => state.invitations)
  const settingsState = useSelector((state) => state.settings)
  const schemasState = useSelector((state) => state.schemas)
  const logo = settingsState.logo

  useEffect(() => {
    console.log(props)
    if (props.setVerifiedCredential !== '') {
      setVerificationStatus(true)
    } else {
      setVerificationStatus(undefined)
    }
  }, [props.verifiedCredential])

  useEffect(() => {
    if (!waitingForInvitation && props.websocket && props.readyForMessages) {
      props.sendRequest('INVITATIONS', 'CREATE', {
        alias: 'Invitation',
        invitationMode: 'once',
        accept: 'auto',
        invitationStatus: 'active',
        invitationDescription: 'Invitation for credential verification.',
      })
      setWaitingForInvitation(true)
    }
  }, [props.websocket, props.readyForMessages])

  useEffect(() => {
    if (invitationsState.invitationURL !== '') {
      setWaitingForConnection(true)
      // setWaitingForInvitation(false) // (eldersonar) Do it only if you want to get a new invitation when a websocket re-connects
    }
    if (contactsState.connection.state === 'active' && waitingForConnection) {
      setConnected(true)
    }
  }, [
    invitationsState.invitationURL,
    contactsState.connection,
    waitingForConnection,
  ])

  // (eldersonar) Setting up governance options
  useEffect(() => {
    let options = []

    // (eldersonar) Handle governance options state (needs better description)
    if (schemasState.verificationSchemas) {
      for (let i = 0; i < schemasState.verificationSchemas.length; i++) {
        console.log(
          schemasState.verificationSchemas[i].schema_id
            .split(':')[2]
            .concat(' ')
            .concat(schemasState.verificationSchemas[i].schema_id.split(':')[3])
        )
        options.push({
          id: schemasState.verificationSchemas[i].schema_id,
          label: schemasState.verificationSchemas[i].schema_id
            .split(':')[2]
            .concat(' ')
            .concat(
              schemasState.verificationSchemas[i].schema_id.split(':')[3]
            ),
          value: schemasState.verificationSchemas[i].schema_id,
        })
      }
      setSchemaOptions(options)
    }
  }, [schemasState.verificationSchemas])

  const SchemaSelect = () => {
    return (
      <SelectBox
        name="schemas"
        placeholder="Select schema to verify..."
        // defaultValue={dbGovernance}
        options={schemaOptions}
        onChange={(e) => requestPresentations(e.value)}
        menuPortalTarget={document.body}
      />
    )
  }

  function requestPresentations(value) {
    if (value) {
      const schema = schemasState.verificationSchemas.find(
        ({ schema_id }) => schema_id === value
      )

      props.sendRequest('PRESENTATIONS', 'REQUEST', {
        connection_id: contactsState.connection.connection_id,
        schema_id: schema.schema_id,
        attributes: schema.schema_attributes,
        discovered_features: contactsState.connection.discovered_features,
      })
      setVerificationStatus(undefined)
      setWaitingForVerification(true)

      if (submitBtn.current) {
        submitBtn.current.setAttribute('disabled', 'disabled')
        submitBtn.current.style.background = 'grey'
        submitBtn.current.style.cursor = 'default'
      }
    } else {
      return
    }
  }

  const verifiedDisplay = () => {
    if (submitBtn.current) {
      submitBtn.current.removeAttribute('disabled')
      submitBtn.current.style = null
    }

    const { verifiedCredential } = props
    const keys = Object.keys(verifiedCredential)

    if (!keys.length) {
      return <p>No fields to display</p>
    }

    return (
      <>
        {keys.map((key) => (
          <p key={key}>
            {key.replace('_', ' ')}: {verifiedCredential[key].raw}
          </p>
        ))}
        <HeaderVerify>Credential Issuance and Verification</HeaderVerify>
        <p>
          Please wait for a credential to be issued or choose a credential to
          verify
        </p>
        <VerChoiceWrapper>
          <SchemaSelect />
        </VerChoiceWrapper>
      </>
    )
  }

  // const handleIssuer = (e) => {
  //   e.preventDefault()
  //   props.history.push('/email-credential')
  // }

  return (
    <>
      <CompFormVerify>
        <FormWrapper>
          <div className="avatar-container left-fold landing-col-6">
            {logo ? <Logo src={logo} alt="Logo" /> : <Logo />}
          </div>
          {connected ? (
            waitingForVerification ? (
              verificationStatus !== undefined ? (
                verificationStatus ? (
                  props.verifiedCredential ? (
                    <>
                      <HeaderVerify>Credentials Verified!</HeaderVerify>
                      {verifiedDisplay()}
                    </>
                  ) : (
                    <>
                      <HeaderVerify>Credentials Verified!</HeaderVerify>
                      <p>No Credential Data Was Passed</p>
                    </>
                  )
                ) : (
                  <>
                    <HeaderVerify>Verification Failed</HeaderVerify>
                    <p>
                      There was a problem verifying your credential. Please try
                      again or contact support by clicking on the link below:
                    </p>
                    <a href="https://indicio.tech/proven-support/">
                      <SubmitBtn>Contact Support</SubmitBtn>
                    </a>
                  </>
                )
              ) : (
                <>
                  <HeaderVerify>
                    Credential Issuance and Verification
                  </HeaderVerify>
                  <p>
                    You will now receive a request on your mobile app to send
                    your credential to us for verification.
                  </p>
                </>
              )
            ) : schemasState.verificationSchemas &&
              schemasState.verificationSchemas.length ? (
              <>
                <HeaderVerify>
                  Credential Issuance and Verification
                </HeaderVerify>
                <p>
                  Please wait for a credential to be issued or choose a
                  credential to verify
                </p>
                <VerChoiceWrapper>
                  <SchemaSelect />
                </VerChoiceWrapper>
              </>
            ) : (
              <>
                <HeaderVerify>
                  Please wait for your credential to be issued
                </HeaderVerify>
              </>
            )
          ) : (
            <>
              <HeaderVerify>
                Welcome to the {settingsState.organizationName} Agent
              </HeaderVerify>
              <p>
                Simply scan the following QR code to receive credentials or
                begin the verification process:
              </p>
              {invitationsState.invitationURL ? (
                <div className="qr">
                  <p>
                    <QR
                      value={invitationsState.invitationURL}
                      size={256}
                      renderAs="svg"
                    />
                  </p>
                </div>
              ) : (
                <p>
                  <span>Loading...</span>
                </p>
              )}
            </>
          )}
          {/* <SubmitBtn onClick={handleIssuer} ref={submitBtn}>
            Go To Email Issuer
          </SubmitBtn> */}
        </FormWrapper>
        <PoweredBox>
          <PoweredBy src={PoweredByImage} alt="Powered By Indicio" />
        </PoweredBox>
      </CompFormVerify>
    </>
  )
}

export default Root
