const localTheme = JSON.parse(localStorage.getItem('recentTheme'))
const defaultTheme = {
  primary_color: '#333545',
  secondary_color: '#FF6701',
  tertiary_color: '#FAD744',
  neutral_color: '#808080',
  negative_color: '#e33636',
  warning_color: '#ff8c42',
  positive_color: '#4CB944',
  text_color: '#555',
  text_light: '#fff',
  border: '1px solid #e3e3e3',
  drop_shadow: '3px 3px 3px rgba(0, 0, 0, 0.3)',
  background_primary: '#fff',
  background_secondary: '#f5f5f5',
}

const initialState = {
  logo: null,
  organizationName: null,
  schemas: {},
  smtp: null,
  siteTitle: '',
  theme: localTheme ? localTheme : defaultTheme,
}

const SET_LOGO = 'SET_LOGO'
const SET_ORGANIZATION_NAME = 'SET_ORGANIZATION_NAME'
const SET_SCHEMAS = 'SET_SCHEMAS'
const SET_SMTP = 'SET_SMTP'
const SET_SITE_TITLE = 'SET_SITE_TITLE'
const SET_THEME = 'SET_THEME'
const CLEAR_SETTINGS_STATE = 'CLEAR_SETTINGS_STATE'

export function setLogo(logo) {
  return {
    type: SET_LOGO,
    payload: logo,
  }
}
export function setOrganizationName(organization) {
  return {
    type: SET_ORGANIZATION_NAME,
    payload: organization,
  }
}
export function setSchemas(schemas) {
  return {
    type: SET_SCHEMAS,
    payload: schemas,
  }
}
export function setSiteTitle(title) {
  return {
    type: SET_SITE_TITLE,
    payload: title,
  }
}
export function setSMTP(smtp) {
  return {
    type: SET_SMTP,
    payload: smtp,
  }
}
export function setTheme(theme) {
  return {
    type: SET_THEME,
    payload: theme,
  }
}
export function clearSettingsState() {
  return {
    type: CLEAR_SETTINGS_STATE,
    payload: {},
  }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LOGO:
      return { ...state, logo: action.payload }

    case SET_ORGANIZATION_NAME:
      return { ...state, organizationName: action.payload }

    case SET_SCHEMAS:
      return { ...state, schemas: action.payload }

    case SET_SITE_TITLE:
      return { ...state, siteTitle: action.payload }

    case SET_SMTP:
      return { ...state, smtp: action.payload }

    case SET_THEME:
      return { ...state, theme: action.payload }

    case CLEAR_SETTINGS_STATE:
      return { ...state, organizationName: null, smtp: null }

    default:
      return state
  }
}
