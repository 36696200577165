import Axios from 'axios'
import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import styled from 'styled-components'
import Cookies from 'universal-cookie'

import AccountSetup from './UI/AccountSetup'
import AdminRoute from './routes/AdminRoute'
import { check } from './UI/CanUser'
import EmailVerification from './UI/EmailVerification'
import ForgotPassword from './UI/ForgotPassword'
import FullPageSpinner from './UI/FullPageSpinner'
import Login from './UI/Login'
import { useNotification } from './UI/NotificationProvider'
import PoweredByImage from './assets/powered-by.png'
import PasswordReset from './UI/PasswordReset'
import FormVerify from './UI/FormVerify'
import Root from './UI/Root'
import SessionProvider from './UI/SessionProvider'
import { handleImageSrc } from './UI/util'

import store from './store'
import {
  setCredentials,
  clearCredentialsState,
} from './redux/credentialsReducer'
import {
  setContacts,
  setContactSelected,
  setConnection,
  setPendingConnections,
  setWaitingForContacts,
  setWaitingForPendingConnections,
  clearContactsState,
  setPingData,
  setPongData,
} from './redux/contactsReducer'
import {
  clearGovernanceState,
  setGovernanceDID,
  setGovernanceMetadata,
  setGovernanceSchemas,
  setGovernanceIssuers,
  setGovernanceRoles,
  setSelectedGovernance,
  setGovernanceIssuersMetadata,
  setSavedFiles,
  setGovernanceInUse,
  setFileUploaded,
} from './redux/governanceReducer'
import {
  setInvitations,
  setInvitationURL,
  setInvitationSelected,
  setWaitingForInvitations,
  clearInvitationsState,
} from './redux/invitationsReducer'
import {
  setLoggedIn,
  setLoggedInUserId,
  setLoggedInUsername,
  setLoggedInRoles,
  setSessionDuration,
  setLoggedInUserState,
  logoutUser,
} from './redux/loginReducer'
import {
  clearNotificationsState,
  setNotificationState,
} from './redux/notificationsReducer'
import {
  setPresentationReports,
  clearPresentationsState,
} from './redux/presentationsReducer'
import {
  setLogo,
  setOrganizationName,
  setSMTP,
  setTheme,
  setSiteTitle,
  clearSettingsState,
} from './redux/settingsReducer'
import {
  setUsers,
  setUser,
  setRoles,
  clearUsersState,
} from './redux/usersReducer'
import {
  getDID,
  setSchemas,
  setAllSchemas,
  setSchema,
  setSchemaSelected,
  setCredDefs,
  setVerificationSchemas,
} from './redux/schemasReducer'
import {
  setWebsocket,
  setReadyForWebsocketMessages,
  clearWebsockets,
} from './redux/websocketsReducer'

import './App.css'
import { PoweredBox, PoweredBy } from './UI/CommonStylesForms'

const Frame = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`
const Main = styled.main`
  flex: 9;
  padding: 30px;
`

function App() {
  const dispatch = useDispatch()
  const loginState = useSelector((state) => state.login)
  const notificationsState = useSelector((state) => state.notifications)
  const settingsState = useSelector((state) => state.settings)
  const websocketsState = useSelector((state) => state.websockets)
  const { notificationType, notificationMessage } = notificationsState
  const { websocket, readyForWebsocketMessages } = websocketsState

  const setNotification = useNotification()

  let currentState
  const updateState = () => {
    //Update to current redux state
    currentState = store.getState()
  }

  // Websocket reference hook
  const controllerSocket = useRef()

  // Used for websocket auto reconnect
  // const [websocket, setWebsocket] = useState(false)
  // const [readyForWebsocketMessages, setReadyForWebsocketMessages] = useState(
  //   websocketsState
  // )

  // (AmmonBurgi) Keeps track of loading processes. The useMemo is necessary to preserve list across re-renders.
  const loadingList = useMemo(() => [], [])
  const [appIsLoaded, setAppIsLoaded] = useState(false)

  // Styles to change array
  const [stylesArray, setStylesArray] = useState([])

  const cookies = new Cookies()
  const [session, setSession] = useState('')
  // const [retryCount, setRetryCount] = useState(0) // (eldersonar) Add when needed for keeping track of websocket reconnection attempts
  const retryDelay = useRef(1000) // initial delay time in ms

  const [focusedConnectionID, setFocusedConnectionID] = useState('')
  const [verifiedCredential, setVerifiedCredential] = useState('')
  const [pendingConnectionID, setPendingConnectionID] = useState('')

  useEffect(() => {
    if ((notificationMessage, notificationType)) {
      setNotification(notificationMessage, notificationType)
      dispatch(clearNotificationsState())
    }
  }, [notificationMessage, notificationType])

  useEffect(() => {
    Axios({
      method: 'GET',
      url: '/api/check-session',
    })
      .then((res) => {
        // (eldersonar) Good session and user is logged in
        console.log('check-session', res)
        if (res.data.logged_in && res.data.id) {
          setSession(cookies.get('sessionId'))
          dispatch(setSessionDuration(res.data.maxAge))
          dispatch(setLoggedIn(true))
          dispatch(setLoggedInUserState(res.data))
          dispatch(setLoggedInUserId(res.data.id))
          dispatch(setLoggedInUsername(res.data.username))
          dispatch(setLoggedInRoles(res.data.roles))
        }
        // (eldersonar) Good session and user is logged off
        else {
          setSession(cookies.get('sessionId'))
          dispatch(setSessionDuration(res.data.maxAge))
          setAppIsLoaded(true)
        }
      })
      .catch((error) => {
        console.log(error)
        // Unauthorized
        if (error.response.status === 401) {
          setAppIsLoaded(false)
          console.log('Unauthorized access')
          window.location.reload()
        } else {
          // (eldersonar) Potential to get a 500 error, but the chances are slim
          console.log('Test the unhandled response code here')
          console.log(error)
          setSession(cookies.get('sessionId'))
          setAppIsLoaded(false)
        }
      })
  }, [loginState.loggedIn])

  // (JamesKEbert) Note: We may want to abstract the websockets out into a high-order component for better abstraction, especially potentially with authentication/authorization
  // Perform First Time Setup. Connect to Controller Server via Websockets
  useEffect(() => {
    const connect = () => {
      if (!controllerSocket.current) {
        let url = new URL('/api/ws', window.location.href)
        url.protocol = url.protocol.replace('http', 'ws')
        controllerSocket.current = new WebSocket(url.href)

        controllerSocket.current.onopen = () => {
          // setWebsocket(true)
          dispatch(setWebsocket(true))
          retryDelay.current = 1000 // reset delay time on successful connection
          // setRetryCount(0) // reset retry count on successful connection
        }

        controllerSocket.current.onclose = (event) => {
          console.log('closing ws')
          // setReadyForWebsocketMessages(false)
          dispatch(setReadyForWebsocketMessages(false))
          // setWebsocket(false)
          dispatch(setWebsocket(true))

          // Reconnect on close
          setTimeout(() => {
            console.log('Reconnecting on close')
            // setRetryCount((prevCount) => prevCount + 1)
            controllerSocket.current = null // close previous socket instance
            connect() // reconnect
          }, retryDelay.current)

          // (eldersonar) Increase delay time for next retry. This will double to the maximum of 1 minute
          retryDelay.current = Math.min(retryDelay.current + 1000, 60000)
        }

        // Error Handler
        controllerSocket.current.onerror = (event) => {
          dispatch(
            setNotificationState({
              message: 'Client Error - Websockets',
              type: 'error',
            })
          )
        }

        // Receive new message from Controller Server
        controllerSocket.current.onmessage = (message) => {
          const parsedMessage = JSON.parse(message.data)

          messageHandler(
            parsedMessage.context,
            parsedMessage.type,
            parsedMessage.data
          )
        }
      }
    }

    if (session) {
      connect()
    }

    return () => {
      if (controllerSocket.current) {
        controllerSocket.current.close()
        // setReadyForWebsocketMessages(false) // update socketOpen state
        dispatch(setReadyForWebsocketMessages(false))
      }
    }
  }, [session])

  // (eldersonar) Set-up site title. What about SEO? Will robots be able to read it?
  useEffect(() => {
    document.title = settingsState.siteTitle
  }, [settingsState.siteTitle])

  // Define Websocket event listeners
  useEffect(() => {
    // Perform operation on websocket open
    // Run web sockets only if authenticated
    if (
      loginState.loggedIn &&
      websocket &&
      readyForWebsocketMessages &&
      loginState.loggedInUserState &&
      loadingList.length === 0
    ) {
      sendMessage('IMAGES', 'GET_ALL', {})
      addLoadingProcess('LOGO')
      sendMessage('SETTINGS', 'GET_THEME', {})
      addLoadingProcess('THEME')
      sendMessage('SCHEMAS', 'GET_DID')
      sendMessage('SCHEMAS', 'SET_SCHEMAS')
      sendMessage('SCHEMAS', 'GET_VERIFICATION_SCHEMAS')

      if (check('presentations:read')) {
        sendMessage('PRESENTATIONS', 'GET_ALL', {})
        addLoadingProcess('PRESENTATIONS')
      }
      if (check('credentials:read')) {
        sendMessage('CREDENTIALS', 'GET_ALL', {})
        addLoadingProcess('CREDENTIALS')
      }
      if (check('roles:read')) {
        sendMessage('ROLES', 'GET_ALL', {})
        addLoadingProcess('ROLES')
      }
      sendMessage('SETTINGS', 'GET_ORGANIZATION', {})
      addLoadingProcess('ORGANIZATION')
      if (check('settings:update')) {
        sendMessage('SETTINGS', 'GET_SMTP', {})
        addLoadingProcess('SMTP')
      }
      if (check('users:read')) {
        sendMessage('USERS', 'GET_ALL', {})
        addLoadingProcess('USERS')
      }
      sendMessage('GOVERNANCE', 'GET_DID', {})
      // addLoadingProcess('DID')
      sendMessage('GOVERNANCE', 'GET_ALL_GOVERNANCE_FILES', {})
      addLoadingProcess('GOVERNANCE_FILES')
      sendMessage('GOVERNANCE', 'GET_GOVERNANCE_IN_USE', {})
      addLoadingProcess('GOVERNANCE_IN_USE')
    } else if (
      !loginState.loggedIn &&
      websocket &&
      readyForWebsocketMessages &&
      loadingList.length === 0
    ) {
      sendMessage('SETTINGS', 'GET_THEME', {})
      addLoadingProcess('THEME')
      sendMessage('SETTINGS', 'GET_ORGANIZATION', {})
      addLoadingProcess('ORGANIZATION')
      sendMessage('IMAGES', 'GET_ALL', {})
      addLoadingProcess('LOGO')
      sendMessage('INVITATIONS', 'RECONNECT', {})
      addLoadingProcess('RECONNECT')
      sendMessage('SCHEMAS', 'GET_VERIFICATION_SCHEMAS')
    }
  }, [
    session,
    loginState.loggedIn,
    websocket,
    readyForWebsocketMessages,
    loginState.loggedInUserState,
  ])

  // Send a message to the Controller server
  function sendMessage(context, type, data = {}) {
    if (websocket) {
      controllerSocket.current.send(JSON.stringify({ context, type, data }))
    }
  }

  // Handle inbound messages
  const messageHandler = async (context, type, data = {}) => {
    updateState()

    try {
      console.log(
        `New Message with context: '${context}' and type: '${type}' with data:`,
        data
      )
      switch (context) {
        case 'INVITATIONS':
          switch (type) {
            case 'INVITATION':
              dispatch(setInvitationURL(data.invitation_url))
              dispatch(setInvitationSelected(data))

              break

            case 'INVITATIONS':
              dispatch(setInvitations(data))
              dispatch(setWaitingForInvitations(false))

              break

            case 'INVITATION_UPDATED':
              console.log('Invitation has been updated!')

              const activeInvitation =
                currentState.invitations.invitationSelected
              if (
                activeInvitation.invitation_id ===
                data.updatedInvitation.invitation_id
              ) {
                dispatch(setInvitationSelected(data.updatedInvitation))
              }
              break

            case 'INVITATION_DELETED':
              console.log(data)
              const index = currentState.invitations.findIndex(
                (v) => v.invitation_id === data
              )
              let alteredInvitations = [...currentState.invitations]
              alteredInvitations.splice(index, 1)
              dispatch(setInvitations(alteredInvitations))

              break

            case 'INVITATIONS_SUCCESS':
              console.log('Invitation Success')
              dispatch(setNotificationState({ message: data, type: 'notice' }))
              break

            case 'RECONNECT':
              if (data.success === true) {
                console.log('Successfully reconnected ws with connection_id')
                removeLoadingProcess('RECONNECT')
              } else {
                console.log(
                  "Warning: Couldn't reconnect ws with connection_id. No connection id is associated with this session"
                )
                removeLoadingProcess('RECONNECT')
              }
              break

            case 'INVITATIONS_ERROR':
              dispatch(
                setNotificationState({ message: data.error, type: 'error' })
              )
              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )
              break
          }
          break

        case 'CONTACTS':
          switch (type) {
            case 'CONTACTS':
              dispatch(setContacts(data.contacts))
              dispatch(setWaitingForContacts(false))

              break

            case 'CONTACT':
              dispatch(setContactSelected(data.contact))

              break

            case 'CONNECTION':
              dispatch(setConnection(data.connection))
              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )
              break
          }
          break

        case 'CONTACT':
          switch (type) {
            case 'PING':
              dispatch(setPingData(data))

              break
            case 'PING_RESPONSE_RECEIVED':
              if (
                data.threadId ==
                currentState.contacts.pingData.pingData.threadId
              ) {
                const pongData = {
                  connectionId:
                    currentState.contacts.pingData.pingData.connectionId,
                }
                dispatch(setPongData(pongData))
              }
              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )

              break
          }
          break

        case 'CONNECTIONS':
          switch (type) {
            case 'PENDING_CONNECTIONS':
              dispatch(setPendingConnections(data.pendingConnections))
              dispatch(setWaitingForPendingConnections(false))

              break

            case 'CONNECTION':
              //(AmmonBurgi) Receives the most recent connection with state "active" or "completed"
              dispatch(setConnection(data.connection))

              break

            default:
              setNotification(
                `Error - Unrecognized Websocket Message Type: ${type}`,
                'error'
              )
              break
          }
          break

        case 'OUT_OF_BAND':
          switch (type) {
            case 'INVITATION':
              dispatch(setInvitationURL(data.invitation_url))
              dispatch(setInvitationSelected(data))
              break

            case 'CONNECTION_REUSE':
              console.log(data.comment)

              const activeInvitation =
                currentState.invitations.invitationSelected
              if (
                activeInvitation.invitation_msg_id === data.invitation_msg_id
              ) {
                const message = `Connection reused for ${data.connection_id}`

                dispatch(
                  setNotificationState({ message: message, type: 'notice' })
                )
              }

              break

            default:
              setNotification(
                `Error - Unrecognized Websocket Message Type: ${type}`,
                'error'
              )
              break
          }
          break

        case 'ROLES':
          switch (type) {
            case 'ROLES':
              let oldRoles = currentState.users.roles
              let newRoles = data.roles
              let updatedRoles = []
              // (mikekebert) Loop through the new roles and check them against the existing array
              newRoles.forEach((newRole) => {
                oldRoles.forEach((oldRole, index) => {
                  if (
                    oldRole !== null &&
                    newRole !== null &&
                    oldRole.role_id === newRole.role_id
                  ) {
                    // (mikekebert) If you find a match, delete the old copy from the old array
                    oldRoles.splice(index, 1)
                  }
                })
                updatedRoles.push(newRole)
              })
              // (mikekebert) When you reach the end of the list of new roles, simply add any remaining old roles to the new array
              if (oldRoles.length > 0)
                updatedRoles = [...updatedRoles, ...oldRoles]

              dispatch(setRoles(updatedRoles))
              removeLoadingProcess('ROLES')
              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )

              break
          }
          break

        case 'USERS':
          switch (type) {
            case 'USERS':
              let oldUsers = currentState.users.users
              let newUsers = data.users
              let updatedUsers = []
              // (mikekebert) Loop through the new users and check them against the existing array
              newUsers.forEach((newUser) => {
                oldUsers.forEach((oldUser, index) => {
                  if (
                    oldUser !== null &&
                    newUser !== null &&
                    oldUser.user_id === newUser.user_id
                  ) {
                    // (mikekebert) If you find a match, delete the old copy from the old array
                    oldUsers.splice(index, 1)
                  }
                })
                updatedUsers.push(newUser)
              })
              // (mikekebert) When you reach the end of the list of new users, simply add any remaining old users to the new array
              if (oldUsers.length > 0)
                updatedUsers = [...updatedUsers, ...oldUsers]
              // (mikekebert) Sort the array by data created, newest on top
              updatedUsers.sort((a, b) =>
                a.created_at < b.created_at ? 1 : -1
              )

              dispatch(setUsers(updatedUsers))
              removeLoadingProcess('USERS')

              break

            case 'USER':
              let user = data.user[0]
              dispatch(setUser(user))
              break

            case 'USER_UPDATED':
              dispatch(
                setUsers(
                  currentState.users.users.map((x) =>
                    x.user_id === data.updatedUser.user_id
                      ? data.updatedUser
                      : x
                  )
                )
              )
              dispatch(setUser(data.updatedUser))
              break

            case 'PASSWORD_UPDATED':
              dispatch(
                setUsers(
                  currentState.users.users.map((x) =>
                    x.user_id === data.updatedUserPassword.user_id
                      ? data.updatedUserPassword
                      : x
                  )
                )
              )
              break

            case 'USER_CREATED':
              let usersCreated = [...currentState.users.users, data.user[0]]
              usersCreated.sort((a, b) =>
                a.created_at < b.created_at ? 1 : -1
              )
              dispatch(setUsers(usersCreated))
              dispatch(setUser(data.user[0]))
              break

            case 'USER_DELETED':
              const currentUsers = currentState.users.users
              const index = currentUsers.findIndex((v) => v.user_id === data)
              let alteredUsers = [...currentUsers]
              alteredUsers.splice(index, 1)
              dispatch(setUsers(alteredUsers))

              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )

              break
          }
          break

        case 'CREDENTIALS':
          switch (type) {
            case 'CREDENTIALS':
              let oldCredentials = currentState.credentials.credentials
              let newCredentials = data.credential_records
              let updatedCredentials = []
              // (mikekebert) Loop through the new credentials and check them against the existing array
              newCredentials.forEach((newCredential) => {
                oldCredentials.forEach((oldCredential, index) => {
                  if (
                    oldCredential !== null &&
                    newCredential !== null &&
                    oldCredential.credential_exchange_id ===
                      newCredential.credential_exchange_id
                  ) {
                    // (mikekebert) If you find a match, delete the old copy from the old array
                    oldCredentials.splice(index, 1)
                  }
                })
                updatedCredentials.push(newCredential)
                // (mikekebert) We also want to make sure to reset any pending connection IDs so the modal windows don't pop up automatically
                if (newCredential.connection_id === focusedConnectionID) {
                  setFocusedConnectionID('')
                }
              })
              // (mikekebert) When you reach the end of the list of new credentials, simply add any remaining old credentials to the new array
              if (oldCredentials.length > 0)
                updatedCredentials = [...updatedCredentials, ...oldCredentials]
              // (mikekebert) Sort the array by data created, newest on top
              updatedCredentials.sort((a, b) =>
                a.created_at < b.created_at ? 1 : -1
              )

              dispatch(setCredentials(updatedCredentials))
              removeLoadingProcess('CREDENTIALS')
              break

            case 'CREDENTIAL_ISSUED':
              console.log('CREDENTIAL_ISSUED')
              console.log(data.state)
              dispatch(
                setNotificationState({
                  message: 'Credential was successfully issued',
                  type: 'notice',
                })
              )

              break

            case 'CREDENTIALS_ERROR':
              dispatch(
                setNotificationState({ message: data.error, type: 'error' })
              )

              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )

              break
          }

          break
        case 'PRESENTATIONS':
          switch (type) {
            case 'VERIFIED':
              dispatch(
                setNotificationState({
                  message: 'Success - Verified Credential',
                  type: 'notice',
                })
              )
              break

            case 'CREDENTIAL_VERIFIED':
              setPendingConnectionID(data.connection_id)
              setVerifiedCredential(data.revealed_attrs)
              break

            case 'VERIFICATION_FAILED':
              setVerifiedCredential('')
              break

            case 'PRESENTATION_REPORTS':
              let oldPresentations =
                currentState.presentations.presentationReports
              let newPresentations = data.presentation_reports
              let updatedPresentations = []

              // (mikekebert) Loop through the new presentation and check them against the existing array
              newPresentations.forEach((newPresentation) => {
                oldPresentations.forEach((oldPresentation, index) => {
                  if (
                    oldPresentation !== null &&
                    newPresentation !== null &&
                    oldPresentation.presentation_exchange_id ===
                      newPresentation.presentation_exchange_id
                  ) {
                    // (mikekebert) If you find a match, delete the old copy from the old array
                    oldPresentations.splice(index, 1)
                  }
                })
                updatedPresentations.push(newPresentation)
                // (mikekebert) We also want to make sure to reset any pending connection IDs so the modal windows don't pop up automatically
                if (newPresentation.connection_id === pendingConnectionID) {
                  setPendingConnectionID('')
                }
              })
              // (mikekebert) When you reach the end of the list of new presentations, simply add any remaining old presentations to the new array
              if (oldPresentations.length > 0)
                updatedPresentations = [
                  ...updatedPresentations,
                  ...oldPresentations,
                ]
              // (mikekebert) Sort the array by date created, newest on top
              updatedPresentations.sort((a, b) =>
                a.created_at < b.created_at ? 1 : -1
              )

              dispatch(setPresentationReports(updatedPresentations))

              removeLoadingProcess('PRESENTATIONS')
              break

            case 'PRESENTATIONS_ERROR':
              console.log(data.error)
              console.log('Presentations Error')
              dispatch(
                setNotificationState({ message: data.error, type: 'error' })
              )
              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )

              break
          }
          break

        case 'SCHEMAS':
          switch (type) {
            case 'GET_DID':
              dispatch(getDID(data))
              break

            case 'SET_SCHEMAS':
              dispatch(setSchemas(data.schemasList.activeSchemas))
              dispatch(setCredDefs(data.schemasList.credentialDefDatabase))
              removeLoadingProcess('SCHEMAS')
              break

            case 'VERIFICATION_SCHEMAS':
              dispatch(setVerificationSchemas(data.schemas))
              break

            case 'SCHEMAS_ERROR':
              dispatch(
                setNotificationState({ message: data.error, type: 'error' })
              )
              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )

              break
          }
          break

        case 'SERVER':
          switch (type) {
            case 'NOTIFICATIONS':
              dispatch(setNotificationState(data))

              break

            case 'SERVER_ERROR':
              console.log(data)
              dispatch(
                setNotificationState({
                  message: `Server Error - ${data.errorCode} \n Reason: '${data.errorReason}'`,
                  type: 'error',
                })
              )

              break

            case 'WEBSOCKET_READY':
              dispatch(setReadyForWebsocketMessages(true))

              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )

              break
          }
          break

        case 'SETTINGS':
          switch (type) {
            case 'SETTINGS_THEME':
              // Writing the recent theme to a local storage
              const stringMessageTheme = JSON.stringify(data.value)
              window.localStorage.setItem('recentTheme', stringMessageTheme)
              dispatch(setTheme(data.value))
              removeLoadingProcess('THEME')
              break

            case 'LOGO':
              dispatch(setLogo(handleImageSrc(data.image.data)))
              removeLoadingProcess('LOGO')
              break

            case 'SETTINGS_ORGANIZATION':
              dispatch(setOrganizationName(data.organizationName))
              dispatch(setSiteTitle(data.title))
              removeLoadingProcess('ORGANIZATION')
              break

            case 'SETTINGS_SMTP':
              dispatch(setSMTP(data.value))
              removeLoadingProcess('SMTP')
              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )

              break
          }
          break

        case 'IMAGES':
          switch (type) {
            case 'IMAGE_LIST':
              dispatch(setLogo(handleImageSrc(data.image.data)))

              removeLoadingProcess('IMAGES')
              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )

              break
          }
          break

        case 'ORGANIZATION':
          switch (type) {
            case 'ORGANIZATION_NAME':
              dispatch(setOrganizationName(data[0].value.name))

              removeLoadingProcess('ORGANIZATION')
              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )

              break
          }
          break

        case 'GOVERNANCE':
          switch (type) {
            case 'DID':
              console.log(data.did)
              dispatch(setGovernanceDID(data.did))
              removeLoadingProcess('DID')
              break

            case 'METADATA':
              console.log(data.metadata)
              const governance = data.metadata.find(
                (metadata) => metadata.selected === true
              )
              dispatch(setSelectedGovernance(governance ? governance : ''))

              dispatch(setGovernanceMetadata(data.metadata))
              removeLoadingProcess('METADATA')
              break

            case 'SCHEMAS':
              console.log(data.schemas)
              dispatch(setGovernanceSchemas(data.schemas))
              removeLoadingProcess('SCHEMAS')
              break

            case 'ISSUERS':
              console.log(data.issuers)
              dispatch(setGovernanceIssuers(data.issuers))
              removeLoadingProcess('ISSUERS')
              break

            case 'ROLES':
              console.log(data.roles)
              dispatch(setGovernanceRoles(data.roles))
              removeLoadingProcess('ROLES')
              break

            case 'ISSUERS_METADATA':
              console.log(data.issuersMetadata)
              dispatch(setGovernanceIssuersMetadata(data.issuersMetadata))
              removeLoadingProcess('ISSUERS_METADATA')
              break

            case 'GOVERNANCE_FILES':
              let oldGovernanceFiles = currentState.governance.savedFiles
              let newGovernanceFiles = data.governanceFiles
              let updatedGovernanceFiles = []
              // (mikekebert) Loop through the new governance files and check them against the existing array
              newGovernanceFiles.forEach((newGovernanceFile) => {
                oldGovernanceFiles.forEach((oldGovernanceFile, index) => {
                  if (
                    oldGovernanceFile !== null &&
                    newGovernanceFile !== null &&
                    oldGovernanceFile.id === newGovernanceFile.id
                  ) {
                    // (mikekebert) If you find a match, delete the old copy from the old array
                    oldGovernanceFiles.splice(index, 1)
                  }
                })
                updatedGovernanceFiles.push(newGovernanceFile)

                if (oldGovernanceFiles.length > 0)
                  updatedGovernanceFiles = [
                    ...updatedGovernanceFiles,
                    ...oldGovernanceFiles,
                  ]

                dispatch(setSavedFiles(updatedGovernanceFiles))
              })
              removeLoadingProcess('GOVERNANCE_FILES')
              break

            case 'GOVERNANCE_FILE':
              console.log(data.governanceFile)
              dispatch(setSelectedGovernance(data.governanceFile))
              //(RomanStepanyan) This state allows governance components to know when to re-wright selected governance state
              //(RomanStepanyan) This state can potentinally be replaced with more optimal code
              dispatch(setFileUploaded(true))
              removeLoadingProcess('GOVERNANCE_FILE')
              break

            case 'GOVERNANCE_IN_USE':
              console.log(data.governanceInUse)
              dispatch(setGovernanceInUse(data.governanceInUse))
              removeLoadingProcess('GOVERNANCE_IN_USE')
              break

            case 'SET_GOVERNANCE_IN_USE':
              console.log(data.governanceInUse)
              const formatedFileName = data.governanceInUse.name
                .concat(' version ')
                .concat(data.governanceInUse.version)
                .concat(' format ')
                .concat(data.governanceInUse.format)
              dispatch(setGovernanceInUse(data.governanceInUse))
              dispatch(
                setNotificationState({
                  message: `Governance file ${formatedFileName} is being used`,
                  type: 'notice',
                })
              )
              removeLoadingProcess('GOVERNANCE_IN_USE')
              break

            case 'GOVERNANCE_FROM_SOURCE':
              dispatch(setSelectedGovernance(data.apiGovernance))
              dispatch(setFileUploaded(true))
              break

            case 'GOVERNANCE_ERROR':
              dispatch(
                setNotificationState({ message: data.error, type: 'error' })
              )

              break

            case 'GOVERNANCE_SUCCESS':
              dispatch(setNotificationState({ message: data, type: 'notice' }))

              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )
              break
          }
          break

        default:
          dispatch(
            setNotificationState({
              message: `Error - Unrecognized Websocket Message Type: ${context}`,
              type: 'error',
            })
          )

          break
      }
    } catch (error) {
      console.log('Error caught:', error)
      console.log(context)
      console.log(type)
      dispatch(
        setNotificationState({
          message: 'Client Error - Websocket',
          type: 'error',
        })
      )
    }
  }

  function addLoadingProcess(process) {
    loadingList.push(process)
  }

  function clearLoadingProcess() {
    loadingList.length = 0
    setAppIsLoaded(true)
  }

  function removeLoadingProcess(process) {
    const index = loadingList.indexOf(process)
    if (index > -1) {
      loadingList.splice(index, 1)
    }

    if (loadingList.length === 0) {
      setAppIsLoaded(true)
    }
  }

  function setUpUser(id, username, roles) {
    dispatch(setLoggedInUserId(id))
    dispatch(setLoggedInUsername(username))
    dispatch(setLoggedInRoles(roles))
  }

  // Update theme state locally
  const updateTheme = (update) => {
    updateState()
    return dispatch(setTheme({ ...currentState.settings.theme, ...update }))
  }

  // Update theme in the database
  const saveTheme = () => {
    sendMessage('SETTINGS', 'SET_THEME', settingsState.theme)
  }

  //(RomanStepanyan) Removing all styles from an array of styles to desible undo button
  const clearStylesArray = () => {
    setStylesArray([])
  }

  const addStylesToArray = (key) => {
    let position = stylesArray.indexOf(key)
    // if cannot find indexOf style
    if (!~position) {
      setStylesArray((oldArray) => [...oldArray, `${key}`])
    }
  }

  const removeStylesFromArray = (undoKey) => {
    // Removing a style from an array of styles
    let index = stylesArray.indexOf(undoKey)
    if (index > -1) {
      stylesArray.splice(index, 1)
      setStylesArray(stylesArray)
    }
  }

  // Undo theme change
  const undoStyle = (undoKey) => {
    const recentTheme = JSON.parse(localStorage.getItem('recentTheme'))
    updateState()

    if (undoKey !== undefined) {
      for (let key in recentTheme)
        if ((key = undoKey)) {
          const undo = { [`${key}`]: recentTheme[key] }
          return dispatch(setTheme({ ...currentState.settings.theme, ...undo }))
        }
    }
  }

  // Logout and redirect
  const handleLogout = (history) => {
    Axios({
      method: 'POST',
      url: '/api/user/log-out',
    }).then((res) => {
      dispatch(logoutUser())
      dispatch(clearCredentialsState())
      dispatch(clearContactsState())
      dispatch(clearGovernanceState())
      dispatch(clearInvitationsState())
      dispatch(clearSettingsState())
      dispatch(clearPresentationsState())
      dispatch(clearUsersState())

      if (history !== undefined) {
        history.push('/admin/login')
      }
    })
  }

  if (
    (loginState.loggedIn && !appIsLoaded) ||
    (!loginState.loggedIn && !appIsLoaded)
  ) {
    // Show the spinner while the app is loading
    return <FullPageSpinner />
  } else if (!loginState.loggedIn && appIsLoaded) {
    return (
      <SessionProvider logout={handleLogout}>
        <Router>
          <Switch>
            <Route
              path="/forgot-password"
              render={({ match, history }) => {
                return (
                  <>
                    <Frame id="app-frame">
                      <Main>
                        <ForgotPassword
                          history={history}
                          sendRequest={sendMessage}
                        />
                      </Main>
                    </Frame>
                    <PoweredBox>
                      <PoweredBy
                        src={PoweredByImage}
                        alt="Powered By Indicio"
                      />
                    </PoweredBox>
                  </>
                )
              }}
            />
            <Route
              path="/password-reset"
              render={({ match, history }) => {
                return (
                  <>
                    <Frame id="app-frame">
                      <Main>
                        <PasswordReset
                          history={history}
                          sendRequest={sendMessage}
                        />
                      </Main>
                    </Frame>
                    <PoweredBox>
                      <PoweredBy
                        src={PoweredByImage}
                        alt="Powered By Indicio"
                      />
                    </PoweredBox>
                  </>
                )
              }}
            />
            <Route
              path="/account-setup"
              render={({ match, history }) => {
                return (
                  <>
                    <Frame id="app-frame">
                      <Main>
                        <AccountSetup
                          history={history}
                          sendRequest={sendMessage}
                          messageHandler={messageHandler}
                        />
                      </Main>
                    </Frame>
                    <PoweredBox>
                      <PoweredBy
                        src={PoweredByImage}
                        alt="Powered By Indicio"
                      />
                    </PoweredBox>
                  </>
                )
              }}
            />
            <Route
              path="/emailVerification"
              render={({ match, history }) => {
                return (
                  <>
                    <Frame id="app-frame">
                      <Main>
                        <EmailVerification
                          history={history}
                          sendRequest={sendMessage}
                          messageHandler={messageHandler}
                        />
                      </Main>
                    </Frame>
                    <PoweredBox>
                      <PoweredBy
                        src={PoweredByImage}
                        alt="Powered By Indicio"
                      />
                    </PoweredBox>
                  </>
                )
              }}
            />
            <Route
              path="/admin/login"
              render={({ match, history }) => {
                return (
                  <>
                    <Frame id="app-frame">
                      <Main>
                        <Login
                          history={history}
                          setUpUser={setUpUser}
                          sendRequest={sendMessage}
                        />
                      </Main>
                    </Frame>
                    <PoweredBox>
                      <PoweredBy
                        src={PoweredByImage}
                        alt="Powered By Indicio"
                      />
                    </PoweredBox>
                  </>
                )
              }}
            />
            {/* <Route
              path="/email-credential"
              exact
              render={() => {
                return <FormVerify sendRequest={sendMessage} />
              }}
            /> */}
            <Route
              path="/"
              exact
              render={({ history }) => {
                return (
                  <Root
                    handleLogout={handleLogout}
                    history={history}
                    websocket={websocket}
                    readyForMessages={readyForWebsocketMessages}
                    sendRequest={sendMessage}
                    verifiedCredential={verifiedCredential}
                  />
                )
              }}
            />
            <Route path="/admin">
              <Redirect to="/admin/login" />
            </Route>
            <Route render={() => <Redirect to="/" />} />
          </Switch>
        </Router>
      </SessionProvider>
    )
  } else {
    // loggedIn and appIsLoaded
    return (
      <SessionProvider logout={handleLogout}>
        <Router>
          <Switch>
            <Route
              path="/admin"
              render={({ history }) => {
                return (
                  <AdminRoute
                    history={history}
                    handleLogout={handleLogout}
                    sendMessage={sendMessage}
                    updateTheme={updateTheme}
                    saveTheme={saveTheme}
                    undoStyle={undoStyle}
                    stylesArray={stylesArray}
                    clearStylesArray={clearStylesArray}
                    addStylesToArray={addStylesToArray}
                    removeStylesFromArray={removeStylesFromArray}
                  />
                )
              }}
            />
            {/* Redirect to root if no route match is found */}
            <Route render={() => <Redirect to="/admin" />} />
          </Switch>
        </Router>
      </SessionProvider>
    )
  }
}

export default App
