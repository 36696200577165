import { DateTime } from 'luxon'
import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import QRCode from 'qrcode.react'
import styled from 'styled-components'

import {
  setInvitationSelected,
  setInvitationURL,
} from '../redux/invitationsReducer'
import { setSchemaSelected } from '../redux/schemasReducer.js'
import { setNotificationState } from '../redux/notificationsReducer'

import {
  StyledPopup,
  InputBox,
  ModalMedium,
  ModalHeader,
  ModalContentWrapper,
  ModalContent,
  CloseBtn,
  Actions,
  CancelBtn,
  SubmitBtnModal,
  ModalLabel,
  InputFieldModal,
} from './CommonStylesForms'
import { setConnection } from '../redux/contactsReducer'

const QR = styled(QRCode)`
  display: block;
  margin: auto;
  padding: 20px;
  width: 300px;
  height: 300px;
  background-color: white;
`

function FormUniversalIssuance(props) {
  const dispatch = useDispatch()
  const schemasState = useSelector((state) => state.schemas)
  const schemaSelected = schemasState.schemaSelected
  const schemaId = props.schemaId
  let [schemaAttributes, setSchemaAttributes] = useState([])
  let mappedAttributes = ''
  const contactsState = useSelector((state) => state.contacts)
  const invitationsState = useSelector((state) => state.invitations)
  const { invitationSelected, invitationURL } = invitationsState
  const schemas = useSelector((state) => state.schemas.schemas)
  const { connection } = contactsState
  const credDefs = useSelector((state) => state.schemas.credDefs)
  const [currentCredDefId, setCurrentCredDefId] = useState('')
  const [schemaDID, setSchemaDID] = useState('')
  const attributes = []

  const universalForm = useRef(null)
  const [connectionFound, setConnectionFound] = useState(false)

  const { contactId, connections } = props

  useEffect(() => {
    // (AmmonBurgi) Find the active connection using the selected invitations connection_id
    if (contactId) {
      setConnectionFound(true)
    } else if (connection && invitationSelected && props.universalModalIsOpen) {
      if (
        connection.connection_id &&
        invitationSelected.connection_id &&
        connection.connection_id === invitationSelected.connection_id
      ) {
        setConnectionFound(true)
      }
    }
  }, [connection, contactId, invitationSelected, props.universalModalIsOpen])

  useEffect(() => {
    schemasState.schemas.forEach((schema) => {
      if (schema.name === props.chosenCredential) {
        dispatch(setSchemaSelected(schema))
        setSchemaAttributes(schema.attribute_names)

        credDefs.forEach((credentialDef) => {
          if (credentialDef.schema_id == schema.sequence_number) {
            setCurrentCredDefId(credentialDef.credential_definition_id)
            // console.log(credentialDef.credential_definition_id, 'credDef')
          } else {
            // console.log('no cred def', credentialDef.schema_id, schema.sequence_number)
          }
        })
        setSchemaDID(schema.schema_id.split(':')[0])
      } else {
        // console.log('no match')
      }
    })
  }, [schemasState.schemas, props.chosenCredential, dispatch])

  const handleClosingModal = () => {
    setConnectionFound(false)
    // (AmmonBurgi) Set Invitation to default values to prevent a false connect
    dispatch(setInvitationSelected({}))
    dispatch(setInvitationURL(''))
    dispatch(setConnection({}))

    props.closeUniversalModal()
  }

  mappedAttributes = schemaAttributes.map((attribute) => {
    return (
      <InputBox attribute={attribute} key={attribute}>
        <ModalLabel htmlFor={attribute}>{attribute}</ModalLabel>
        <InputFieldModal name={attribute} id={attribute} placeholder="" />
      </InputBox>
    )
  })

  const handleSubmit = (e) => {
    e.preventDefault()

    const form = new FormData(universalForm.current)

    const universalAttr = []

    schemaAttributes.forEach((attribute) => {
      universalAttr.push({
        name: attribute,
        value: form.get(attribute),
      })
    })

    if (contactId) {
      connections.forEach((connection) =>
        props.sendRequest('CREDENTIALS', 'ISSUE_USING_SCHEMA', {
          connectionID: connection.connection_id,
          schemaID: schemaSelected.schema_id,
          schemaVersion: schemaSelected.version,
          schemaName: schemaSelected.name,
          schemaIssuerDID: schemaDID,
          attributes: universalAttr,
          credDefID: currentCredDefId,
          issuerDID: schemasState.issuerDID,
        })
      )
    } else {
      props.sendRequest('CREDENTIALS', 'ISSUE_USING_SCHEMA', {
        // connectionID: contactId ? contactId : connection.connection_id,
        connectionID: connection.connection_id,
        schemaID: schemaSelected.schema_id,
        schemaVersion: schemaSelected.version,
        schemaName: schemaSelected.name,
        schemaIssuerDID: schemaDID,
        attributes: universalAttr,
        credDefID: currentCredDefId,
        issuerDID: schemasState.issuerDID,
      })
    }

    handleClosingModal()
    dispatch(
      setNotificationState({
        message: `${props.chosenCredential} Credential is on its way!`,
        type: 'notice',
      })
    )
  }

  return (
    <StyledPopup
      open={props.universalModalIsOpen}
      closeOnDocumentClick
      onClose={handleClosingModal}
    >
      <ModalMedium className="modal">
        <ModalHeader>Issue {props.chosenCredential} Credential</ModalHeader>
        <ModalContentWrapper>
          {(connection || contactId) && connectionFound ? (
            <ModalContent>
              <form onSubmit={handleSubmit} ref={universalForm}>
                <div>{mappedAttributes}</div>
                <Actions>
                  <CancelBtn type="button" onClick={handleClosingModal}>
                    Cancel
                  </CancelBtn>
                  <SubmitBtnModal type="submit">Send</SubmitBtnModal>
                </Actions>
              </form>
            </ModalContent>
          ) : (
            <ModalContent>
              <p>
                Simply scan the following QR code to begin the issuance process:
              </p>
              {invitationsState.invitationURL ? (
                <div className="qr">
                  <p>
                    <QR
                      value={invitationsState.invitationURL}
                      size={256}
                      renderAs="svg"
                    />
                  </p>
                </div>
              ) : (
                <p>
                  <span>Loading...</span>
                </p>
              )}
            </ModalContent>
          )}
        </ModalContentWrapper>
        <CloseBtn onClick={handleClosingModal}>&times;</CloseBtn>
      </ModalMedium>
    </StyledPopup>
  )
}

export default FormUniversalIssuance
