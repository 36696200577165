const initialState = {
  contacts: {},
  contactSelected: {},
  connection: {},
  pendingConnections: {},
  waitingForContacts: true,
  waitingForPendingConnections: true,
  pingData: {},
  pongData: {},
}

const SET_CONTACTS = 'SET_CONTACTS'
const SET_CONTACT_SELECTED = 'SET_CONTACT_SELECTED'
const SET_CONNECTION = 'SET_CONNECTION'
const SET_PENDING_CONNECTIONS = 'SET_PENDING_CONNECTIONS'
const SET_WAITING_FOR_CONTACTS = 'SET_WAITING_FOR_CONTACTS'
const SET_WAITING_FOR_PENDING_CONNECTIONS =
  'SET_WAITING_FOR_PENDING_CONNECTIONS'
const CLEAR_CONTACTS_STATE = 'CLEAR_CONTACTS_STATE'

const SET_PING_DATA = 'SET_PING_DATA'
const SET_PONG_DATA = 'SET_PONG_DATA'

export function setContacts(contacts) {
  return {
    type: SET_CONTACTS,
    payload: contacts,
  }
}
export function setContactSelected(contact) {
  return {
    type: SET_CONTACT_SELECTED,
    payload: contact,
  }
}
export function setConnection(connection) {
  return {
    type: SET_CONNECTION,
    payload: connection,
  }
}
export function setPendingConnections(connections) {
  return {
    type: SET_PENDING_CONNECTIONS,
    payload: connections,
  }
}
export function setWaitingForContacts(waiting) {
  return {
    type: SET_WAITING_FOR_CONTACTS,
    payload: waiting,
  }
}
export function setWaitingForPendingConnections(waiting) {
  return {
    type: SET_WAITING_FOR_PENDING_CONNECTIONS,
    payload: waiting,
  }
}
export function setPingData(pingData) {
  return {
    type: SET_PING_DATA,
    payload: pingData,
  }
}
export function setPongData(pongData) {
  return {
    type: SET_PONG_DATA,
    payload: pongData,
  }
}
export function clearContactsState() {
  return {
    type: CLEAR_CONTACTS_STATE,
    payload: null,
  }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CONTACTS:
      return { ...state, contacts: action.payload }

    case SET_CONTACT_SELECTED:
      return { ...state, contactSelected: action.payload }

    case SET_CONNECTION:
      return { ...state, connection: action.payload }

    case SET_PENDING_CONNECTIONS:
      return { ...state, pendingConnections: action.payload }

    case SET_WAITING_FOR_CONTACTS:
      return { ...state, waitingForContacts: action.payload }

    case SET_WAITING_FOR_PENDING_CONNECTIONS:
      return { ...state, waitingForPendingConnections: action.payload }

    case SET_PING_DATA:
      return { ...state, pingData: action.payload }

    case SET_PONG_DATA:
      return { ...state, pongData: action.payload }

    case CLEAR_CONTACTS_STATE:
      return initialState

    default:
      return state
  }
}
