import React from 'react'
import { useSelector } from 'react-redux'

import PageHeader from './PageHeader'
import PageSection from './PageSection'

import {
  DataTable,
  DataRow,
  DataHeader,
  DataCell,
  AttributeTable,
  AttributeRow,
} from './CommonStylesTables'
import { Button } from './CommonStylesForms'

function Schemas(props) {
  const schemasState = useSelector((state) => state.schemas)
  const schemas = schemasState.schemas

  const history = props.history

  let issuerDID =
    schemasState.issuerDID === null
      ? `Your agent's DID has not been anchored to a ledger. Please run first time setup or contact Indicio for assistance.`
      : schemasState.issuerDID

  function openSchema(history, id) {
    if (history !== undefined) {
      history.push('/admin/schemas/' + id)
    }
  }

  function compileSchemas() {
    props.sendRequest('SCHEMAS', 'SET_SCHEMAS')
  }
  function fetchActiveSchemas() {
    console.log('Fetch Active Schemas', schemasState.schemaSelected)
  }
  function fetchAllSchemas() {
    console.log('Fetch All Schemas')
    console.log(schemasState)
  }

  function retrieveDID() {
    props.sendRequest('SCHEMAS', 'GET_DID')
  }

  const schemaRows = schemas.map((schema) => {
    return (
      <DataRow
        key={schema.schema_id}
        onClick={() => {
          openSchema(history, schema.schema_id, schema)
        }}
      >
        <DataCell>{schema.name}</DataCell>
        <DataCell>{schema.schema_id}</DataCell>
        <DataCell>{schema.version}</DataCell>
        <DataCell></DataCell>
      </DataRow>
    )
  })

  let compileButton =
    schemasState.issuerDID === null ? (
      <p>
        Your agent's DID needs to be anchored to a ledger to compile schemas.
        Please run first time setup or contact Indicio for assistance.
      </p>
    ) : (
      <Button
        style={{ float: 'left', padding: '15px', marginBottom: '15px' }}
        onClick={compileSchemas}
      >
        Compile
      </Button>
    )

  let compilationUI = (
    <div style={{ overflow: 'hidden', paddingBottom: '3px' }}>
      <div>{compileButton}</div>
    </div>
  )

  return (
    <>
      <div id="contacts">
        <PageHeader title={'Schemas'} />
        <PageSection>
          <h2>Issuer Information</h2>
          <AttributeTable>
            <tbody>
              <AttributeRow>
                <th>Issuer DID:</th>
                <td>{issuerDID}</td>
              </AttributeRow>
            </tbody>
          </AttributeTable>
          <Button
            style={{ float: 'left', padding: '15px', marginTop: '15px' }}
            onClick={retrieveDID}
          >
            Retrieve DID
          </Button>
        </PageSection>
        <PageSection>
          {compilationUI}
          <DataTable>
            <thead>
              <DataRow>
                <DataHeader>Schema Name</DataHeader>
                <DataHeader>Schema ID</DataHeader>
                <DataHeader>Version</DataHeader>
                <DataHeader></DataHeader>
              </DataRow>
            </thead>
            <tbody>{schemaRows}</tbody>
          </DataTable>
        </PageSection>
      </div>
    </>
  )
}

export default Schemas
