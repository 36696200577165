import React, { useRef, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Axios from 'axios'
import styled from 'styled-components'
import Select from 'react-select'

import {
  setInvitationSelected,
  setInvitationURL,
} from '../redux/invitationsReducer'
import { setNotificationState } from '../redux/notificationsReducer'

import FormInvitationEmail from './FormInvitationEmail'
import FormDriversLicenseIssuance from './FormDriversLicenseIssuance'
import FormUniversalIssuance from './FormUniversalIssuance'

import PageHeader from './PageHeader.js'
import PageSection from './PageSection.js'
import PageSection2 from './PageSection2.js'

import { Button, SubmitBtnModal, Modal, Actions } from './CommonStylesForms'

const HomeHeader = styled.h2`
  display: inline-block;
  margin-right: 10px;
  font-size: 1.3em;
`
const SelectContainer = styled.div`
  float: left;
  z-index: 100;
  width: 300px;
  margin-right: 15px;
  overflow: visible;
`

const SubmitFormBtn = styled.button``
const BlockInput = styled.input`
  display: block;
  margin-bottom: 15px;
`

const Input = styled.input``

const Form = styled.form`
  overflow: hidden;
  margin-bottom: 10px;
`

function Home(props) {
  const dispatch = useDispatch()
  const settingsState = useSelector((state) => state.settings)
  const schemasState = useSelector((state) => state.schemas)
  const organizationName = settingsState.organizationName
  const submitBtn = useRef()

  const [emailModalIsOpen, setEmailModalIsOpen] = useState(false)
  const closeEmailModal = () => setEmailModalIsOpen(false)

  const [driversLicenseModalIsOpen, setDriversLicenseModalIsOpen] = useState(
    false
  )
  const closeDriversLicenseModal = () => setDriversLicenseModalIsOpen(false)

  const [universalModalIsOpen, setUniversalModalIsOpen] = useState(false)
  const closeUniversalModal = () => setUniversalModalIsOpen(false)

  const [chosenCredential, setChosenCredential] = useState('')
  const [schemasOptions, setSchemasOptions] = useState([])

  const [csvData, setCsvData] = useState([])

  useEffect(() => {
    if (schemasState.schemas) {
      for (let i = 0; i < schemasState.schemas.length; i++) {
        schemasOptions.push({
          value: schemasState.schemas[i].name,
          label: schemasState.schemas[i].name,
        })
      }
    }
  }, [schemasState.schemas])

  useEffect(() => {
    if (csvData.length > 0) {
      submitBtn.current.removeAttribute('disabled')
    }
  }, [csvData])

  const onBtnClick = (e) => {
    if (submitBtn.current) {
      submitBtn.current.setAttribute('disabled', 'disabled')
    }
  }

  function handleCredChange(e) {
    setChosenCredential(e.label)
  }

  function handleOpen(e) {
    e.preventDefault()
    console.log(chosenCredential)
    if (chosenCredential === 'Email') {
      setEmailModalIsOpen((o) => !o)
    } else if (chosenCredential === 'Drivers_License') {
      // (AmmonBurgi) We need to reset the invitation so the modal doesn't proceed before a new invitation is received.
      dispatch(setInvitationSelected({}))
      dispatch(setInvitationURL(''))

      setDriversLicenseModalIsOpen((o) => !o)
      props.sendRequest('INVITATIONS', 'CREATE', {
        alias: 'Invitation',
        invitationMode: 'once',
        accept: 'auto',
        invitationStatus: 'active',
        invitationDescription:
          "Invitation for Driver's License credential issuance.",
      })
    } else {
      // (AmmonBurgi) We need to reset the invitation so the modal doesn't proceed before a new invitation is received.
      dispatch(setInvitationSelected({}))
      dispatch(setInvitationURL(''))

      setUniversalModalIsOpen((o) => !o)
      props.sendRequest('INVITATIONS', 'CREATE', {
        alias: 'Invitation',
        invitationMode: 'once',
        accept: 'auto',
        public: false,
        invitationStatus: 'active',
        invitationDescription: 'Invitation for Universal credential issuance.',
      })
    }
  }

  //(RomanStepanyan) Converting CSV file to an array of objects
  function csvToArray(str, delimiter = ',') {
    const rows = str.split(/(?:\r\n|\n)+/).filter((el) => {
      return el.length !== 0
    })
    const headers = rows.splice(0, 1)[0].split(',')

    const arr = rows.map(function (row) {
      //Splitting and removing white spaces
      const values = row.replace(/\s/g, '').split(delimiter)
      //Assemble the object
      const el = headers.reduce((object, header, index) => {
        object[header] = values[index]
        return object
      }, {})
      return el
    })
    setCsvData(arr)
  }

  //(RomanStepanyan) CSV file upload
  let csvFileSelectHandler = (event) => {
    event.preventDefault()
    const file = event.target.files[0]
    console.log('file:', file)

    if (file) {
      const reader = new FileReader()
      reader.onload = function (e) {
        csvToArray(reader.result)
      }
      reader.readAsText(file)
    }
    dispatch(
      setNotificationState({
        message: `Your CSV file, ${file.name}, has been imported. Next, click on "Send Emails".`,
        type: 'notice',
      })
    )
  }

  const handleCsvSubmit = async (e) => {
    e.preventDefault()
    onBtnClick()
    if (csvData) {
      //(RomanStepanyan) Sending an array with converted CSV data
      Axios({
        method: 'POST',
        data: {
          emails: csvData,
        },
        url: '/api/emails/verify',
      }).then((res) => {
        if (res.data.error) {
          dispatch(
            setNotificationState({ message: res.data.error, type: 'error' })
          )
        } else {
          dispatch(
            setNotificationState({
              message: 'Email invitations have been sent!',
              type: 'notice',
            })
          )
        }
      })
    } else {
      dispatch(
        setNotificationState({
          message: 'The CSV file is not selected.',
          type: 'error',
        })
      )
    }
  }

  return (
    <>
      {/* welcome to the ___ enterprise issuer */}
      <PageHeader title={organizationName} />
      <PageSection2>
        <form>
          <HomeHeader>Workflow</HomeHeader>
          <p>Select a Credential to Issue:</p>
          <SelectContainer>
            <Select
              className="react-select-container"
              id="chosenCred"
              name="chosenCred"
              placeholder="---Choose Credential---"
              onChange={(e) => handleCredChange(e)}
              options={schemasOptions}
            />
          </SelectContainer>
          <SubmitBtnModal
            type="submit"
            onClick={(e) => {
              handleOpen(e)
            }}
          >
            Issue
          </SubmitBtnModal>
        </form>
      </PageSection2>
      <PageSection>
        <HomeHeader>Bulk Import Mails</HomeHeader>
        <p>
          Proven provides several ways to issue email credentials. Users can
          request an email invitation using the self-serve UI, administrators
          send invitations one-by-one or by uploading a CSV file in the admin
          UI, and developers request invitations via the API.
        </p>
        <Form onSubmit={handleCsvSubmit}>
          <input type="file" accept=".csv" onChange={csvFileSelectHandler} />
          <button type="submit" ref={submitBtn} disabled>
            Send Emails
          </button>
        </Form>
      </PageSection>
      <FormInvitationEmail
        emailModalIsOpen={emailModalIsOpen}
        closeEmailModal={closeEmailModal}
      />
      <FormDriversLicenseIssuance
        driversLicenseModalIsOpen={driversLicenseModalIsOpen}
        closeDriversLicenseModal={closeDriversLicenseModal}
        sendRequest={props.sendRequest}
      />
      <FormUniversalIssuance
        universalModalIsOpen={universalModalIsOpen}
        closeUniversalModal={closeUniversalModal}
        chosenCredential={chosenCredential}
        sendRequest={props.sendRequest}
      />
    </>
  )
}

export default Home
