import { createStore, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

import contactsReducer from './redux/contactsReducer'
import credentialsReducer from './redux/credentialsReducer'
import invitationsReducer from './redux/invitationsReducer'
import governanceReducer from './redux/governanceReducer'
import loginReducer from './redux/loginReducer'
import notificationsReducer from './redux/notificationsReducer'
import presentationsReducer from './redux/presentationsReducer'
import settingsReducer from './redux/settingsReducer'
import usersReducer from './redux/usersReducer'
import schemasReducer from './redux/schemasReducer'
import websocketsReducer from './redux/websocketsReducer'

const rootReducer = combineReducers({
  contacts: contactsReducer,
  credentials: credentialsReducer,
  invitations: invitationsReducer,
  governance: governanceReducer,
  login: loginReducer,
  notifications: notificationsReducer,
  presentations: presentationsReducer,
  settings: settingsReducer,
  schemas: schemasReducer,
  users: usersReducer,
  websockets: websocketsReducer,
})

export default createStore(rootReducer, composeWithDevTools())
