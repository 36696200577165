import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import AppHeader from '../UI/AppHeader'
import { Switch, Route, Redirect } from 'react-router-dom'
import styled from 'styled-components'

import { check } from '../UI/CanUser'
import Contact from '../UI/Contact'
import Contacts from '../UI/Contacts'
import Credential from '../UI/Credential'
import Credentials from '../UI/Credentials'
import Help from '../UI/Help'
import Home from '../UI/Home'
import Presentation from '../UI/Presentation'
import Presentations from '../UI/Presentations'

import Governance from '../modules/GovernanceEditor/Core/Governance'

import GovernanceIssuerV1 from '../modules/GovernanceEditor/Formats/Format1/Issuer1'
import GovernanceIssuersV1 from '../modules/GovernanceEditor/Formats/Format1/Issuers1'
import GovernanceSchemaV1 from '../modules/GovernanceEditor/Formats/Format1/Schema1'
import GovernanceSchemasV1 from '../modules/GovernanceEditor/Formats/Format1/Schemas1'

// Format 2.0
import GovernanceSchemasV2 from '../modules/GovernanceEditor/Formats/Format2/Schemas2'
import GovernanceSchemaV2 from '../modules/GovernanceEditor/Formats/Format2/Schema2'
import GovernanceIssuersV2 from '../modules/GovernanceEditor/Formats/Format2/Issuers2'
import GovernanceIssuerV2 from '../modules/GovernanceEditor/Formats/Format2/Issuer2'

import Invitation from '../UI/Invitation'
import Invitations from '../UI/Invitations'
import User from '../UI/User'
import Users from '../UI/Users'
import Settings from '../UI/Settings'
import Schemas from '../UI/Schemas'
import Schema from '../UI/Schema'
import UsersGuide from '../UI/UsersGuide.js'

const Frame = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`
const Main = styled.main`
  flex: 9;
  padding: 30px;
`

function AdminRoutes(props) {
  const { path } = useRouteMatch()
  const governanceState = useSelector((state) => state.governance)

  // (eldersonar) This will give the warning only if the admin decided to refresh the page
  // while making updates to the governance file (governance routes only).
  useEffect(() => {
    const myEvent = window.attachEvent || window.addEventListener
    const chkevent = window.attachEvent ? 'onbeforeunload' : 'beforeunload'

    const handleBeforeUnload = (e) => {
      const pathname = props.history.location.pathname
      const pattern = /^\/admin\/governance($|\/|\/*)/
      if (pattern.test(pathname)) {
        const confirmationMessage = 'Are you sure to leave the page?' // This custom message won't be displayed.
        ;(e || window.event).returnValue = confirmationMessage
        return confirmationMessage
      }
    }

    myEvent(chkevent, handleBeforeUnload)

    return () => {
      window.removeEventListener(chkevent, handleBeforeUnload)
    }
  }, [])

  return (
    <>
      <Switch>
        <Route exact path={`${path}/forgot-password`}>
          <Redirect to="/admin" />
        </Route>
        <Route exact path={`${path}/password-reset`}>
          <Redirect to="/admin" />
        </Route>
        <Route exact path={`${path}/account-setup`}>
          <Redirect to="/admin" />
        </Route>
        <Route exact path={`${path}/login`}>
          <Redirect to="/admin" />
        </Route>
        <Route
          path="/admin"
          exact
          render={({ match, history }) => {
            return (
              <Frame id="app-frame">
                <AppHeader
                  match={match}
                  history={history}
                  handleLogout={props.handleLogout}
                />
                <Main>
                  <Home sendRequest={props.sendMessage} />
                </Main>
              </Frame>
            )
          }}
        />
        <Route
          exact
          path={`${path}/invitations`}
          render={({ match, history }) => {
            if (check('invitations:read')) {
              return (
                <Frame id="app-frame">
                  <AppHeader
                    match={match}
                    history={history}
                    handleLogout={props.handleLogout}
                  />
                  <Main>
                    <Invitations
                      history={history}
                      sendRequest={props.sendMessage}
                    />
                  </Main>
                </Frame>
              )
            } else {
              return <Route render={() => <Redirect to="/" />} />
            }
          }}
        />
        <Route
          path={`${path}/invitations/:invitationId`}
          render={({ match, history }) => {
            if (check('invitations:read')) {
              return (
                <Frame id="app-frame">
                  <AppHeader
                    match={match}
                    history={history}
                    handleLogout={props.handleLogout}
                  />
                  <Main>
                    <Invitation
                      history={history}
                      sendRequest={props.sendMessage}
                      invitationId={match.params.invitationId}
                    />
                  </Main>
                </Frame>
              )
            } else {
              return <Route render={() => <Redirect to="/" />} />
            }
          }}
        />
        <Route
          path={`${path}/contacts`}
          exact
          render={({ match, history }) => {
            if (check('contacts:read')) {
              return (
                <Frame id="app-frame">
                  <AppHeader
                    match={match}
                    history={history}
                    handleLogout={props.handleLogout}
                  />
                  <Main>
                    <Contacts
                      history={history}
                      sendRequest={props.sendMessage}
                    />
                  </Main>
                </Frame>
              )
            } else {
              return <Route render={() => <Redirect to="/" />} />
            }
          }}
        />
        <Route
          path={`${path}/contacts/:contactId`}
          render={({ match, history }) => {
            if (check('contacts:read')) {
              return (
                <Frame id="app-frame">
                  <AppHeader
                    match={match}
                    history={history}
                    handleLogout={props.handleLogout}
                  />
                  <Main>
                    <Contact
                      history={history}
                      sendRequest={props.sendMessage}
                      contactId={match.params.contactId}
                    />
                  </Main>
                </Frame>
              )
            } else {
              return <Route render={() => <Redirect to="/" />} />
            }
          }}
        />

        <Route
          path={`${path}/schemas`}
          exact
          render={({ match, history }) => {
            if (check('settings:update')) {
              return (
                <Frame id="app-frame">
                  <AppHeader
                    match={match}
                    history={history}
                    handleLogout={props.handleLogout}
                  />
                  <Main>
                    <Schemas
                      history={history}
                      sendRequest={props.sendMessage}
                    />
                  </Main>
                </Frame>
              )
            } else {
              return <Route render={() => <Redirect to="/" />} />
            }
          }}
        />

        <Route
          path={`${path}/schemas/:schemaId`}
          render={({ match, history }) => {
            if (check('settings:update')) {
              return (
                <Frame id="app-frame">
                  <AppHeader
                    match={match}
                    history={history}
                    handleLogout={props.handleLogout}
                  />
                  <Main>
                    <Schema
                      history={history}
                      sendRequest={props.sendMessage}
                      schemaId={match.params.schemaId}
                    />
                  </Main>
                </Frame>
              )
            } else {
              return <Route render={() => <Redirect to="/" />} />
            }
          }}
        />

        <Route
          path={`${path}/presentations`}
          exact
          render={({ match, history }) => {
            return (
              <Frame id="app-frame">
                <AppHeader
                  match={match}
                  history={history}
                  handleLogout={props.handleLogout}
                />
                <Main>
                  <Presentations history={history} />
                </Main>
              </Frame>
            )
          }}
        />
        <Route
          path={`${path}/presentations/:presentationId`}
          render={({ match, history }) => {
            return (
              <Frame id="app-frame">
                <AppHeader
                  match={match}
                  history={history}
                  handleLogout={props.handleLogout}
                />
                <Main>
                  <Presentation
                    history={history}
                    presentation={match.params.presentationId}
                  />
                </Main>
              </Frame>
            )
          }}
        />
        <Route
          path={`${path}/credentials`}
          exact
          render={({ match, history }) => {
            if (check('credentials:read')) {
              return (
                <Frame id="app-frame">
                  <AppHeader
                    match={match}
                    history={history}
                    handleLogout={props.handleLogout}
                  />
                  <Main>
                    <Credentials history={history} />
                  </Main>
                </Frame>
              )
            } else {
              return <Route render={() => <Redirect to="/" />} />
            }
          }}
        />
        <Route
          path={`${path}/credentials/:credentialId`}
          render={({ match, history }) => {
            if (check('credentials:read')) {
              return (
                <Frame id="app-frame">
                  <AppHeader
                    match={match}
                    history={history}
                    handleLogout={props.handleLogout}
                  />
                  <Main>
                    <Credential
                      history={history}
                      credential={match.params.credentialId}
                    />
                  </Main>
                </Frame>
              )
            } else {
              return <Route render={() => <Redirect to="/" />} />
            }
          }}
        />
        <Route
          path={`${path}/verification`}
          render={({ match, history }) => {
            return (
              <Frame id="app-frame">
                <AppHeader
                  match={match}
                  history={history}
                  handleLogout={props.handleLogout}
                />
                <Main>
                  <p>Verification</p>
                </Main>
              </Frame>
            )
          }}
        />
        <Route
          path={`${path}/messages`}
          render={({ match, history }) => {
            return (
              <Frame id="app-frame">
                <AppHeader
                  match={match}
                  history={history}
                  handleLogout={props.handleLogout}
                />
                <Main>
                  <p>Messages</p>
                </Main>
              </Frame>
            )
          }}
        />
        <Route
          path={`${path}/users`}
          render={({ match, history }) => {
            if (check('users:read')) {
              return (
                <Frame id="app-frame">
                  <AppHeader
                    match={match}
                    history={history}
                    handleLogout={props.handleLogout}
                  />
                  <Main>
                    <Users sendRequest={props.sendMessage} />
                  </Main>
                </Frame>
              )
            } else {
              return <Route render={() => <Redirect to="/" />} />
            }
          }}
        />
        <Route
          path={`${path}/users/:userId`}
          render={({ match, history }) => {
            return (
              <Frame id="app-frame">
                <AppHeader
                  match={match}
                  history={history}
                  handleLogout={props.handleLogout}
                />
                <Main>
                  <User history={history} />
                </Main>
              </Frame>
            )
          }}
        />
        <Route
          path={`${path}/help`}
          exact
          render={({ match, history }) => {
            return (
              <Frame id="app-frame">
                <AppHeader
                  match={match}
                  history={history}
                  handleLogout={props.handleLogout}
                />
                <Main>
                  <Help />
                </Main>
              </Frame>
            )
          }}
        />
        <Route
          path={`${path}/help/users-guide`}
          exact
          render={({ match, history }) => {
            return (
              <Frame id="app-frame">
                <AppHeader
                  match={match}
                  history={history}
                  handleLogout={props.handleLogout}
                />
                <Main>
                  <UsersGuide />
                </Main>
              </Frame>
            )
          }}
        />
        <Route
          path={`${path}/settings`}
          render={({ match, history }) => {
            if (check('settings:update')) {
              return (
                <Frame id="app-frame">
                  <AppHeader
                    match={match}
                    history={history}
                    handleLogout={props.handleLogout}
                  />
                  <Main>
                    <Settings
                      updateTheme={props.updateTheme}
                      saveTheme={props.saveTheme}
                      undoStyle={props.undoStyle}
                      stylesArray={props.stylesArray}
                      addStylesToArray={props.addStylesToArray}
                      clearStylesArray={props.clearStylesArray}
                      removeStylesFromArray={props.removeStylesFromArray}
                      sendRequest={props.sendMessage}
                    />
                  </Main>
                </Frame>
              )
            } else {
              return <Route render={() => <Redirect to="/" />} />
            }
          }}
        />
        {/* (eldersonar) Render components for governance file format 1.0 */}
        {/*governanceState.metadata.format === '1.0' && (
          <>
            <Route
              path={`${path}/governance`}
              exact
              render={({ match, history }) => {
                return (
                  <Frame id="app-frame">
                    <AppHeader
                      match={match}
                      handleLogout={props.handleLogout}
                    />
                    <Main>
                      <Governance sendRequest={props.sendMessage} />
                    </Main>
                  </Frame>
                )
              }}
            />

            <Route
              path={`${path}/governance/schemas`}
              exact
              render={({ match, history }) => {
                return (
                  <Frame id="app-frame">
                    <AppHeader
                      match={match}
                      history={history}
                      handleLogout={props.handleLogout}
                    />
                    <Main>
                      <GovernanceSchemasV1 history={history} />
                    </Main>
                  </Frame>
                )
              }}
            />
            <Route
              path={`${path}/governance/schemas/:id`}
              render={({ match, history }) => {
                return (
                  <Frame id="app-frame">
                    <AppHeader
                      match={match}
                      history={history}
                      handleLogout={props.handleLogout}
                    />
                    <Main>
                      <GovernanceSchemaV1
                        history={history}
                        id={match.params.id}
                      />
                    </Main>
                  </Frame>
                )
              }}
            />
            <Route
              path={`${path}/governance/issuers`}
              exact
              render={({ match, history }) => {
                return (
                  <Frame id="app-frame">
                    <AppHeader
                      match={match}
                      history={history}
                      handleLogout={props.handleLogout}
                    />
                    <Main>
                      <GovernanceIssuersV1 history={history} />
                    </Main>
                  </Frame>
                )
              }}
            />
            <Route
              path={`${path}/governance/issuers/:issuerId`}
              render={({ match, history }) => {
                return (
                  <Frame id="app-frame">
                    <AppHeader
                      match={match}
                      history={history}
                      handleLogout={props.handleLogout}
                    />
                    <Main>
                      <GovernanceIssuerV1
                        history={history}
                        issuerId={match.params.issuerId}
                      />
                    </Main>
                  </Frame>
                )
              }}
            />
            {// Redirect to root if no route match is found }
            <Route render={() => <Redirect to={`${path}/governance`} />} />
          </>
        )*/}
        {/* (eldersonar) Render components for governance file format 2.0  or when governance file not selected*/}
        {/*(governanceState.metadata.format === '2.0' ||
          !governanceState.metadata.format) && (
          <>
            <Route
              path={`${path}/governance`}
              exact
              render={({ match, history }) => {
                return (
                  <Frame id="app-frame">
                    <AppHeader
                      match={match}
                      handleLogout={props.handleLogout}
                    />
                    <Main>
                      <Governance sendRequest={props.sendMessage} />
                    </Main>
                  </Frame>
                )
              }}
            />
            <Route
              path={`${path}/governance/schemas`}
              exact
              render={({ match, history }) => {
                return (
                  <Frame id="app-frame">
                    <AppHeader
                      match={match}
                      history={history}
                      handleLogout={props.handleLogout}
                    />
                    <Main>
                      <GovernanceSchemasV2 history={history} />
                    </Main>
                  </Frame>
                )
              }}
            />
            <Route
              path={`${path}/governance/schemas/:id`}
              render={({ match, history }) => {
                return (
                  <Frame id="app-frame">
                    <AppHeader
                      match={match}
                      history={history}
                      handleLogout={props.handleLogout}
                    />
                    <Main>
                      <GovernanceSchemaV2
                        history={history}
                        id={match.params.id}
                      />
                    </Main>
                  </Frame>
                )
              }}
            />
            <Route
              path={`${path}/governance/issuers`}
              exact
              render={({ match, history }) => {
                return (
                  <Frame id="app-frame">
                    <AppHeader
                      match={match}
                      history={history}
                      handleLogout={props.handleLogout}
                    />
                    <Main>
                      <GovernanceIssuersV2 history={history} />
                    </Main>
                  </Frame>
                )
              }}
            />
            <Route
              path={`${path}/governance/issuers/:issuerId`}
              render={({ match, history }) => {
                return (
                  <Frame id="app-frame">
                    <AppHeader
                      match={match}
                      history={history}
                      handleLogout={props.handleLogout}
                    />
                    <Main>
                      <GovernanceIssuerV2
                        history={history}
                        issuerId={match.params.issuerId}
                      />
                    </Main>
                  </Frame>
                )
              }}
            />

            { // Redirect to root if no route match is found }
            <Route render={() => <Redirect to={`${path}/governance`} />} />
          </>
        )*/}
        {/* Redirect to root if no route match is found */}
        <Route render={() => <Redirect to="/admin" />} />
      </Switch>
    </>
  )
}

export default AdminRoutes
