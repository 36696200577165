import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { formatSeconds } from './util'

import {
  Actions,
  StyledPopup,
  SubmitBtnModal,
  Modal,
  ModalHeader,
  ModalContent,
  TextWrapper,
} from './CommonStylesForms'

import { setSessionDuration } from '../redux/loginReducer'

const SessionAlertContext = React.createContext()

// Exporting the session alert component
export default function SessionAlertProvider(props) {
  const dispatch = useDispatch()
  const loginState = useSelector((state) => state.login)

  const [open, setOpen] = useState(false)
  const [keepAlive, setKeepAlive] = useState(true)
  const [idleCountdown, setIdleCountdown] = useState(299)
  const [finalCountdown, setFinalCountdown] = useState(300)

  const closeModal = () => {
    setOpen(false)
  }

  // ----------------------------- Session management -------------------------
  // Handling form submit
  const handleSubmit = (e) => {
    e.preventDefault()

    closeModal()
    setFinalCountdown(300)
    setKeepAlive(true)

    // Resetting the session cookie and local timer
    Axios({
      method: 'GET',
      url: '/api/extend-session',
    }).then((res) => {
      if (res.data) {
        dispatch(setSessionDuration(res.data.maxAge))
      }
    })
  }
  // Setting a session timer
  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (loginState.sessionDuration) {
        const updatedTimer = loginState.sessionDuration - 1
        if (updatedTimer === 1) {
          // Resetting the session cookie and local timer
          Axios({
            method: 'GET',
            url: '/api/extend-session',
          }).then((res) => {
            console.log('extend-session', res)
            // (eldersonar) This will reset the timer regardless of whether the user is logged in or not.
            // For the logged in user the timer will get reset and the session extended for another 3 minutes,
            // for the logged out user the new session will be set with a new expiration time
            if (!loginState.loggedIn) {
              window.location.reload()
            } else {
              dispatch(setSessionDuration(res.data.maxAge))
            }
          })
        } else {
          dispatch(setSessionDuration(updatedTimer))
        }
      }
    }, 60000)

    return () => {
      clearInterval(timerInterval)
    }
  }, [loginState.sessionDuration])
  // ----------------------------- Session management -------------------------

  // ----------------------------- Idle countdown -------------------------
  // Setting idle countdown
  useEffect(() => {
    if (!loginState.loggedIn) {
      return
    }

    const countdownInterval = setInterval(() => {
      setIdleCountdown((prevCountdown) => {
        if (prevCountdown > 0 && !open) {
          return prevCountdown - 1
        } else if (prevCountdown === 0) {
          setOpen(true)
          setKeepAlive(false)
        }
        return prevCountdown
      })

      if (finalCountdown === 1) {
        closeModal()
        props.logout()
        setOpen(false)
        setIdleCountdown(299)
        setFinalCountdown(300)
      }
    }, 1000)

    const resetTimeout = () => {
      setIdleCountdown(299)
    }

    const events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keydown',
    ]

    events.forEach((event) => {
      window.addEventListener(event, resetTimeout)
    })

    return () => {
      clearInterval(countdownInterval)

      events.forEach((event) => {
        window.removeEventListener(event, resetTimeout)
      })
    }
  }, [idleCountdown, finalCountdown, loginState.loggedIn, open])

  // Setting the final countdown
  useEffect(() => {
    const secondsInterval = setInterval(() => {
      if (finalCountdown >= 0 && !keepAlive && loginState.loggedIn && open) {
        setFinalCountdown(finalCountdown - 1)
        SecondsTimer()
      }
    }, 1000)
    return () => {
      clearInterval(secondsInterval)
    }
  })
  // ----------------------------- Idle countdown -------------------------

  const SecondsTimer = () => {
    return (
      <TextWrapper>
        <h3>The session will expire in</h3>
        <br />
        <h2> {formatSeconds(finalCountdown)}</h2>
        <h3>Extend my session</h3>
      </TextWrapper>
    )
  }

  const sessionAlert =
    (
      <StyledPopup
        open={open}
        closeOnDocumentClick={false}
        onClose={closeModal}
      >
        <Modal>
          <ModalHeader>You've been inactive for a while...</ModalHeader>
          <form id="form" onSubmit={handleSubmit}>
            <ModalContent>
              <SecondsTimer />
            </ModalContent>
            <Actions>
              <SubmitBtnModal type="submit">Yes</SubmitBtnModal>
            </Actions>
          </form>
        </Modal>
      </StyledPopup>
    ) || ''

  return (
    <SessionAlertContext.Provider>
      {sessionAlert}
      {props.children}
    </SessionAlertContext.Provider>
  )
}
