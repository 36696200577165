const initialState = {
  issuerDID: null,
  schemas: [],
  schema: {},
  schemaSelected: {},
  allSchemas: [],
  verificationSchemas: [],
  credDefs: [],
}

const GET_DID = 'GET_DID'
const SET_SCHEMAS = 'SET_SCHEMAS'
const SET_SCHEMA = 'SET_SCHEMA'
const SET_SCHEMA_SELECTED = 'SET_SCHEMA_SELECTED'
const CLEAR_SCHEMAS_STATE = 'CLEAR_SCHEMAS_STATE'
const SET_ALL_SCHEMAS = 'SET_ALL_SCHEMAS'
const SET_VERIFICATION_SCHEMAS = 'SET_VERIFICATION_SCHEMAS'
const SET_CRED_DEFS = 'SET_CRED_DEFS'

export function getDID(DID) {
  return {
    type: GET_DID,
    payload: DID,
  }
}

export function setSchemas(schemas) {
  return {
    type: SET_SCHEMAS,
    payload: schemas,
  }
}

export function setAllSchemas(allSchemas) {
  return {
    type: SET_ALL_SCHEMAS,
    payload: allSchemas,
  }
}

export function setSchema(schema) {
  return {
    type: SET_SCHEMA,
    payload: schema,
  }
}

export function setSchemaSelected(schema) {
  return {
    type: SET_SCHEMA_SELECTED,
    payload: schema,
  }
}

export function setVerificationSchemas(verificationSchemas) {
  return {
    type: SET_VERIFICATION_SCHEMAS,
    payload: verificationSchemas,
  }
}

export function clearSchemasState() {
  return {
    type: CLEAR_SCHEMAS_STATE,
    payload: null,
  }
}

export function setCredDefs(creddefs) {
  return {
    type: SET_CRED_DEFS,
    payload: creddefs,
  }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DID:
      return { ...state, issuerDID: action.payload }

    case SET_SCHEMAS:
      return { ...state, schemas: action.payload }

    case SET_ALL_SCHEMAS:
      return { ...state, allSchemas: action.payload }

    case SET_SCHEMA:
      return { ...state, schema: action.payload }

    case SET_SCHEMA_SELECTED:
      return { ...state, schemaSelected: action.payload }

    case SET_VERIFICATION_SCHEMAS:
      return { ...state, verificationSchemas: action.payload }

    case CLEAR_SCHEMAS_STATE:
      return initialState

    case SET_CRED_DEFS:
      return { ...state, credDefs: action.payload }

    default:
      return state
  }
}
