import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import ReactTooltip from 'react-tooltip'
import Select from 'react-select'

import styled, { useTheme } from 'styled-components'

import { setNotificationState } from '../../../redux/notificationsReducer'
import GovernanceMetadataAdd from '../Formats/Format1/MetadataAdd'
import GovernanceMetadataEdit from '../Formats/Format1/MetadataEdit'

import {
  setSelectedGovernance,
  setFileUploaded,
} from '../../../redux/governanceReducer'

import {
  handleMetadataInjection1_0,
  handleRolesInjection1_0,
  handleSchemasInjection1_0,
  handleIssuersInjection1_0,
  handleIssuersMetadataInjection1_0,
} from '../Formats/Format1/DataInjection1'

import { handleIssuersInjection2_0 } from '../Formats/Format2/DataInjection2'

import {
  handleMetadataExtraction1_0,
  handleSchemasExtraction1_0,
  handleIssuersExtraction1_0,
  handleRolesExtraction1_0,
} from '../Formats/Format1/DataExtraction1'

import PageHeader from '../../../UI/PageHeader'
import PageSection from '../../../UI/PageSection'
import {
  AttributeTable,
  AttributeRow,
  IconHelp,
} from '../Styles/CommonStylesTables'

const GovernanceHeader = styled.h3`
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 0;
`
const EditBtn = styled.button`
  width: 80px;
  background: ${(props) => props.theme.primary_color};
  padding: 10px;
  margin: 5px;
  color: ${(props) => props.theme.text_light};
  border: none;
  float: right;
  box-shadow: ${(props) => props.theme.drop_shadow};
`
const ExportBtn = styled.button`
  width: 140px;
  background: ${(props) => props.theme.primary_color};
  padding: 10px;
  margin: 5px;
  color: ${(props) => props.theme.text_light};
  border: none;
  float: right;
  box-shadow: ${(props) => props.theme.drop_shadow};
`
const Input = styled.input``
const Form = styled.form`
  overflow: hidden;
  margin-bottom: 10px;
`
const SubmitFormBtn = styled.button``
const SettingsHeader = styled.h2`
  display: inline-block;
  margin-right: 10px;
`
const H3 = styled.h3`
  margin: 5px 0;
`

function Governance(props) {
  const dispatch = useDispatch()
  const governanceState = useSelector((state) => state.governance)

  const [selectedFile, setSelectedFile] = useState({})

  const [editMetadataModalIsOpen, setEditMetadataModalIsOpen] = useState(false)
  const closeEditMetadataModal = () => setEditMetadataModalIsOpen(false)

  const [addMetadataModalIsOpen, setAddMetadataModalIsOpen] = useState(false)
  const closeAddMetadataModal = () => setAddMetadataModalIsOpen(false)

  const governanceForm = useRef(null)
  const governancePath = useRef(null)

  useEffect(() => {
    if (
      governanceState.fileUploaded &&
      governanceState.selectedGovernance &&
      Object.keys(governanceState.selectedGovernance).length !== 0 &&
      Object.getPrototypeOf(governanceState.selectedGovernance) ===
        Object.prototype
    ) {
      handleDataInjection()
    }
    setSelectionEvent(false)
  }, [governanceState.selectedGovernance, governanceState.fileUploaded])

  const addMetadata = () => {
    setAddMetadataModalIsOpen(true)
  }

  const editMetadata = () => {
    setEditMetadataModalIsOpen(true)
  }

  // (eldersonar) This state allows us to control the value of the governance selection (from files)
  const [selectedFileHTML, setSelectedFileHTML] = useState(null)

  let governanceSelectHandler = (event) => {
    const file = event.target.files[0]

    // (eldersonar) Save HTML element to a local state
    setSelectedFileHTML(event.target)

    if (file) {
      const fileReader = new FileReader()
      fileReader.readAsText(event.target.files[0], 'UTF-8')
      fileReader.onload = (e) => {
        setSelectedFile(JSON.parse(e.target.result))
      }
    }
    dispatch(
      setNotificationState({
        message: `The governance file, ${file.name}, has been selected. Next, click on "Upload".`,
        type: 'notice',
      })
    )
  }

  // (eldersonar) Handle data by format 1.0
  const uploadFormat1_0 = (file) => {
    handleMetadataInjection1_0(file, dispatch)
    handleRolesInjection1_0(file, dispatch)
    handleSchemasInjection1_0(file, dispatch)
    handleIssuersInjection1_0(file, dispatch)
    handleIssuersMetadataInjection1_0(file, dispatch)
  }

  // (eldersonar) Handle data by format 1.0
  const uploadFormat2_0 = (file) => {
    handleMetadataInjection1_0(file, dispatch)
    handleRolesInjection1_0(file, dispatch)
    handleSchemasInjection1_0(file, dispatch)
    handleIssuersInjection2_0(file, dispatch)
    handleIssuersMetadataInjection1_0(file, dispatch)
  }

  // (eldersonar) Handle governance injection based on the format type
  const handleGovernanceUpload = async (e) => {
    e.preventDefault()
    // (eldersonar) Clear selection for governance file (from DB)
    setDbGovernance('')
    dispatch(setSelectedGovernance(selectedFile))
    //(RomanStepanyan) This state allows governance components to know when to re-wright selected governance state
    //(RomanStepanyan) This state can potentinally be replaced with more optimal code
    dispatch(setFileUploaded(true))
  }

  const handleDataInjection = () => {
    if (
      governanceState.selectedGovernance &&
      governanceState.selectedGovernance.format === '1.0'
    ) {
      uploadFormat1_0(governanceState.selectedGovernance)
    } else if (
      governanceState.selectedGovernance &&
      governanceState.selectedGovernance.format === '2.0'
    ) {
      uploadFormat2_0(governanceState.selectedGovernance)
    } else {
      // (eldersonar) Do we want to reject unsupported file formats or just inject data based on format 1.0?
      dispatch(
        setNotificationState({
          message: `Uploading a file that is not governance, or governance format is not supported`,
          type: 'error',
        })
      )
    }
    //(RomanStepanyan) This state allows governance components to know when to re-wright selected governance state
    //(RomanStepanyan) This state can potentinally be replaced with more optimal code
    dispatch(setFileUploaded(false))
  }

  // (eldersonar) Handle goverance file download
  const downloadFile = () => {
    // (eldersonar) Fetch extracted governance file
    const file = extractGovernance()

    // Handle JSON file download
    if (file) {
      if (governanceState.issuersMetadata.author !== 'DID not anchored') {
        // create file in browser
        const fileName = 'governance-framework-upload-test'
        const json = JSON.stringify(file, null, 2)
        const blob = new Blob([json], { type: 'application/json' })
        const href = URL.createObjectURL(blob)

        // create HTML element with href to file
        const link = document.createElement('a')
        link.href = href
        link.download = fileName + '.json'
        document.body.appendChild(link)
        link.click()

        // clean up element & remove ObjectURL to avoid memory leak
        document.body.removeChild(link)
        URL.revokeObjectURL(href)
        dispatch(
          setNotificationState({
            message: 'Selected governance file is downloaded',
            type: 'notice',
          })
        )
      } else {
        dispatch(
          setNotificationState({
            message: 'Publishing without public DID is forbidden',
            type: 'error',
          })
        )
      }
    } else {
      dispatch(
        setNotificationState({
          message: `Governance file is not selected, or the governance format is not supported`,
          type: 'error',
        })
      )
    }
  }

  const extractGovernance = () => {
    // (eldersonar) Save as UNIX timestamp
    const timestamp = Math.floor(Date.now() / 1000)

    let result = {}

    if (
      governanceState.metadata.format === '1.0' ||
      governanceState.metadata.format === '2.0'
    ) {
      const entries = handleIssuersExtraction1_0()
      const metadata = handleMetadataExtraction1_0(dispatch)
      const roles = {
        roles: Object.assign({}, ...handleRolesExtraction1_0()),
      }
      const schemas = { schemas: handleSchemasExtraction1_0() }
      const issuers = {}

      issuers.participants = {
        id: governanceState.issuersMetadata.id
          ? governanceState.issuersMetadata.id
          : '9b1deb4d-test-uuid-9bdd-2b0d7b3dcb6d',

        // (eldersonar) This is a temporary solution till the community decides on where the "author" lives
        author: governanceState.metadata.author
          ? governanceState.metadata.author
          : 'DID not anchored',
        created: timestamp,
        version: governanceState.issuersMetadata.version
          ? governanceState.issuersMetadata.version
          : governanceState.metadata.version,
        topic: governanceState.issuersMetadata.topic || 'No topic provided',
        entries: Object.assign({}, ...entries), //convert an array of objects to a single object
      }

      result = {
        ...metadata,
        ...schemas,
        ...issuers,
        ...roles,
      }
      // (eldersonar) This is simulating final result of the governance file
      console.log(result)

      // (eldersonar) TODO: explore canonicalization and signatures

      return result
    } else {
      // (eldersonar) governance format is not supported
      return null
    }
  }

  // (eldersonar) TODO: needs better naming
  const [dbGovernance, setDbGovernance] = useState('')
  const [appliedGovernance, setAppliedGovernance] = useState('')

  // const [governanceInUse, setGovernanceInUse] = useState(undefined)
  const [governanceOptions, setGovernanceOptions] = useState([])
  const [selectionEvent, setSelectionEvent] = useState(false)

  // ---------------------First dropdown-------------------
  // (eldersonar) Setting up governance options
  useEffect(() => {
    let options = []

    // (eldersonar) Handle governance options state (needs better description)
    if (governanceState.savedFiles) {
      for (let i = 0; i < governanceState.savedFiles.length; i++) {
        options.push({
          id: governanceState.savedFiles[i].id,
          label: governanceState.savedFiles[i].name
            .concat(' version ')
            .concat(governanceState.savedFiles[i].version)
            .concat(' format ')
            .concat(governanceState.savedFiles[i].format),
          value: governanceState.savedFiles[i].id,
        })
      }
      setGovernanceOptions(options)
    }
  }, [governanceState.savedFiles])

  // (eldersonar) Setting up selected governance
  useEffect(() => {
    // (eldersonar) Handle selected governance state
    if (
      governanceState.selectedGovernance &&
      Object.keys(governanceState.selectedGovernance).length !== 0 &&
      Object.getPrototypeOf(governanceState.selectedGovernance) ===
        Object.prototype &&
      selectionEvent
    ) {
      setDbGovernance({
        id: governanceState.selectedGovernance.id,
        label: governanceState.selectedGovernance.name
          .concat(' version ')
          .concat(governanceState.selectedGovernance.version)
          .concat(' format ')
          .concat(governanceState.selectedGovernance.format),
        value: governanceState.selectedGovernance.id,
      })
    }
  }, [governanceState.selectedGovernance, selectionEvent])
  // ---------------------First dropdown-------------------

  // ---------------------Second dropdown-------------------
  // (eldersonar) Setting up governance options (needs better description)

  // (eldersonar) Setting up selected governance
  useEffect(() => {
    // (eldersonar) Handle selected governacne in use
    if (
      governanceState.governanceInUse &&
      Object.keys(governanceState.governanceInUse).length !== 0 &&
      Object.getPrototypeOf(governanceState.governanceInUse) ===
        Object.prototype
    ) {
      setAppliedGovernance({
        id: governanceState.governanceInUse.id || '',
        label:
          governanceState.governanceInUse.name
            .concat(' version ')
            .concat(governanceState.governanceInUse.version)
            .concat(' format ')
            .concat(governanceState.governanceInUse.format) || '',
        value: governanceState.governanceInUse.id || '',
      })
    }
  }, [governanceState.governanceInUse])
  // ---------------------Second dropdown-------------------

  const OptionSelect = () => {
    return (
      <Select
        name="governance_paths"
        placeholder="Select governance..."
        defaultValue={dbGovernance}
        options={governanceOptions}
        onChange={(e) => selectGovernance(e.value)}
        menuPortalTarget={document.body}
      />
    )
  }

  const GovernanceList = () => {
    return (
      <Select
        name="governance_paths"
        placeholder="Apply governance to this agent..."
        defaultValue={appliedGovernance}
        options={governanceOptions}
        onChange={(e) => applyGovernance(e.value)}
        menuPortalTarget={document.body}
      />
    )
  }

  function selectGovernance(value) {
    // (eldersonar) Update reset the value of the governance selection HTML element
    if (selectedFileHTML) {
      setSelectedFileHTML((prev) => {
        const updatedState = prev
        updatedState.value = null
        return updatedState
      })
    }

    setSelectionEvent(true)
    props.sendRequest('GOVERNANCE', 'GET_GOVERNANCE_FILE_BY_ID', { id: value })
  }

  function applyGovernance(value) {
    // (eldersonar) Set this governance file to be rule the agent
    props.sendRequest('GOVERNANCE', 'SET_GOVERNANCE_IN_USE', { id: value })
  }

  const handleSubmit = (e) => {
    console.log('on handlesubmit')
    e.preventDefault()
    e.target.reset()
  }

  const addGovernance = (e) => {
    e.preventDefault()
    // (eldersonar) Clear selection for governance file (from DB)
    setDbGovernance('')
    const form = new FormData(governanceForm.current)
    const goverancePath = form.get('governance_path')

    //(RomanStepanyan) Checking if a URL starts with http or https
    const urlMatch =
      goverancePath.match('http://') || goverancePath.match('https://')
    if (urlMatch && urlMatch.index === 0) {
      props.sendRequest('GOVERNANCE', 'GET_GOVERNANCE_VIA_API', goverancePath)
    } else {
      return dispatch(
        setNotificationState({
          message:
            "URL address is not correct and governance file can't be selected",
          type: 'error',
        })
      )
    }
    governanceForm.current.reset()
  }

  const saveFile = () => {
    // (eldersonar) Fetch extracted governance file
    const file = extractGovernance()
    if (!file) {
      dispatch(
        setNotificationState({
          message: 'No governance file is selected to save',
          type: 'error',
        })
      )
    } else {
      const id = file.id
      props.sendRequest('GOVERNANCE', 'SAVE_GOVERNANCE_FILE', { id, file })
    }
  }

  return (
    <>
      <PageHeader title="Ecosystem Governance" />
      <PageSection>
        <GovernanceHeader>Governance Metadata</GovernanceHeader>
        <EditBtn
          onClick={() =>
            governanceState.metadata &&
            Object.keys(governanceState.metadata).length !== 0 &&
            Object.getPrototypeOf(governanceState.metadata) === Object.prototype
              ? editMetadata()
              : dispatch(
                  setNotificationState({
                    message:
                      "Can't edit metadata before selecting governance file",
                    type: 'error',
                  })
                )
          }
        >
          Edit
        </EditBtn>
        <EditBtn onClick={() => addMetadata()}>Create</EditBtn>
        <AttributeTable>
          <tbody>
            <AttributeRow>
              <th>Author:</th>
              <td>
                {governanceState.metadata !== undefined
                  ? governanceState.metadata.author || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Name:</th>
              <td>
                {governanceState.metadata !== undefined
                  ? governanceState.metadata.name || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Description:</th>
              <td>
                {governanceState.metadata !== undefined
                  ? governanceState.metadata.description || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Version:</th>
              <td>
                {governanceState.metadata !== undefined
                  ? governanceState.metadata.version || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Format:</th>
              <td>
                {governanceState.metadata !== undefined
                  ? governanceState.metadata.format || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Human Readable Version URI:</th>
              <td>
                {governanceState.metadata !== undefined
                  ? governanceState.metadata.docs_uri || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Last Updated:</th>
              <td>
                {governanceState.metadata &&
                Object.keys(governanceState.metadata).length !== 0 &&
                Object.getPrototypeOf(governanceState.metadata) ===
                  Object.prototype
                  ? new Date(governanceState.metadata.last_updated * 1000)
                      .toISOString()
                      .slice(0, 19)
                      .replace('T', ' ') || ''
                  : ''}
              </td>
            </AttributeRow>
          </tbody>
        </AttributeTable>
        <H3>Get governance from files</H3>
        <Form onSubmit={handleGovernanceUpload}>
          <Input
            type="file"
            accept=".json"
            onChange={governanceSelectHandler}
          ></Input>
          <SubmitFormBtn type="submit">Upload</SubmitFormBtn>
        </Form>
        <hr></hr>
        <Form onSubmit={handleSubmit} ref={governanceForm}>
          <H3>Get governance via API</H3>
          <Input
            type="url"
            name="governance_path"
            ref={governancePath}
            placeholder="https://mrg.com/governance.json"
            required
          />
          <SubmitFormBtn type="submit" onClick={addGovernance}>
            Upload
          </SubmitFormBtn>
        </Form>
        <hr></hr>
        <H3>Get governance from the database</H3>
        <OptionSelect />
        <ExportBtn onClick={() => saveFile()}>Save to database</ExportBtn>
        <ExportBtn onClick={() => downloadFile()}>Download to files</ExportBtn>
      </PageSection>
      <PageSection>
        <SettingsHeader>Governance In Use</SettingsHeader>
        <IconHelp
          data-tip
          data-for="governanceTip"
          data-delay-hide="250"
          data-multiline="true"
          alt="Help"
        />
        <ReactTooltip
          id="governanceTip"
          effect="solid"
          type="info"
          backgroundColor={useTheme().primary_color}
        >
          <span>
            Select a governance file
            <br />
            to be used on this agent.
          </span>
        </ReactTooltip>
        <H3>Governance file options</H3>
        <GovernanceList />
      </PageSection>
      <GovernanceMetadataAdd
        addMetadataModalIsOpen={addMetadataModalIsOpen}
        closeAddMetadataModal={closeAddMetadataModal}
      />
      <GovernanceMetadataEdit
        editMetadataModalIsOpen={editMetadataModalIsOpen}
        closeEditMetadataModal={closeEditMetadataModal}
      />
    </>
  )
}

export default Governance
